// src/UnderwritingDisplay.js

import React, { useState } from 'react'
import { View, StyleSheet, Pressable, useWindowDimensions } from 'react-native'
import { IconButton, Icon } from 'native-base';
import * as Constants from '../theme'
import { AntDesign, Feather } from '@expo/vector-icons';
import Text from './Text';
import { hyphenate } from "hyphen/en"
import * as Data from './libs/data'

export default function MedicationDisplay(props) {
    // Add testID to the root View
    const testProps = { testID: "mdisp", type: "Medication" };

    const widthForDisplayItem = 'clamp(70px,20vw,175px)';
    const maxWidthForDisplayItem = 'clamp(70px,20vw,175px)';
    const styles = new StyleSheet.create({
        flexRow: {
            flex: 1,
            flexDirection: 'row'
        },
        itemName: {
            fontWeight: '700',
            marginRight: 55,
            maxWidth: widthForDisplayItem,
            marginTop: 30,
            marginBottom: 30,
            minWidth: widthForDisplayItem,
            alignSelf: 'center',
        },
        itemUse: {
            fontWeight: '500',
            color: Constants.colors.captionColor,
            maxWidth: maxWidthForDisplayItem,
            marginTop: 30,
            marginBottom: 30,
            minWidth: widthForDisplayItem,
            alignSelf: 'center',
        },
        displayCard: {
            minHeight: 85,
            maxHeight: 'fit-contents',
            borderBottomWidth: 1,
            borderColor: Constants.colors.gray,
            marginLeft: Constants.leftOffset * -1,
            paddingLeft: Constants.leftOffset,
            opacity: props.openItemWizard ? .3 : 1,
            zIndex: -4,
            overflow: 'hidden',
        },
        topRow: {
            alignItems: 'center',
        },
        bottomRow: {
            display: 'none',
            marginBottom: 20,
        },
        itemLastFill: {
            marginRight: 14.75,
        },
        itemFirstFill: {
            marginRight: 14.75,
        },
        icon: {
            height: 16,
            width: 16,
        },
        idx: {
            fontSize: 63,
            fontWeight: '700',
            lineHeight: 76.24,

            maxHeight: 96,
            marginTop: 0,
            opacity: .1,
            position: 'absolute',
            marginLeft: -30 + 8, // 8px from the left padding
            paddingLeft: 0,
            color: Constants.colors.primaryColorBG,
        }
    });

    let [name, setName] = useState(props.name);
    hyphenate(props.name).then(result => setName(result));

    return <Display {...props} part1={name} part2={props.use} testProps={testProps} />

    return (<View style={styles.displayCard}>
        <View style={[styles.flexRow, styles.topRow, { justifyContent: 'space-between' }]}>
            <Text style={styles.idx}>{props.id}</Text>
            <View style={styles.flexRow}>
                <Text style={styles.itemName}>{name}</Text>
                <Text style={styles.itemUse}>{props.use}</Text>
            </View>
            <View style={[{ display: 'flex', flexDirection: 'row', marginRight: 60, }]}>
                <Pressable onPress={() => { if (props.openItemWizard) return; props.setOpenItem(props.uuid); props.setOpenItemWizard(true); }} style={[styles.icon, { marginRight: 20 }]} >
                    <svg width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0956 0.58653L10.5816 2.10056L14.6482 6.16717L16.1622 4.65314C16.9443 3.8711 16.9443 2.60419 16.1622 1.82215L14.9297 0.58653C14.1477 -0.19551 12.8808 -0.19551 12.0987 0.58653H12.0956ZM9.87462 2.80752L2.58288 10.1024C2.25756 10.4277 2.01982 10.8313 1.88843 11.2723L0.781065 15.0355C0.702861 15.3014 0.774808 15.5861 0.968754 15.78C1.1627 15.9739 1.44736 16.0459 1.71013 15.9708L5.4733 14.8635C5.91437 14.7321 6.31791 14.4943 6.64324 14.169L13.9412 6.87413L9.87462 2.80752Z"
                            fill={Constants.colors.linkColor} />
                    </svg>


                </Pressable>
                <Pressable style={[styles.icon]} onPress={() => {
                    props.setDeleteItem(props.uuid);
                }}>
                    <svg width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.22296 1.05313C4.39155 0.7125 4.73809 0.5 5.11586 0.5H8.8748C9.25257 0.5 9.59911 0.7125 9.7677 1.05313L9.99249 1.5H12.9897C13.5423 1.5 13.9887 1.94687 13.9887 2.5C13.9887 3.05313 13.5423 3.5 12.9897 3.5H1.00101C0.448405 3.5 0.00195312 3.05313 0.00195312 2.5C0.00195312 1.94687 0.448405 1.5 1.00101 1.5H3.99817L4.22296 1.05313ZM1.00101 4.5H12.9897V14.5C12.9897 15.6031 12.0936 16.5 10.9915 16.5H2.99911C1.89703 16.5 1.00101 15.6031 1.00101 14.5V4.5ZM3.99817 6.5C3.72343 6.5 3.49864 6.725 3.49864 7V14C3.49864 14.275 3.72343 14.5 3.99817 14.5C4.27291 14.5 4.4977 14.275 4.4977 14V7C4.4977 6.725 4.27291 6.5 3.99817 6.5ZM6.99533 6.5C6.72059 6.5 6.4958 6.725 6.4958 7V14C6.4958 14.275 6.72059 14.5 6.99533 14.5C7.27007 14.5 7.49486 14.275 7.49486 14V7C7.49486 6.725 7.27007 6.5 6.99533 6.5ZM9.99249 6.5C9.71775 6.5 9.49297 6.725 9.49297 7V14C9.49297 14.275 9.71775 14.5 9.99249 14.5C10.2672 14.5 10.492 14.275 10.492 14V7C10.492 6.725 10.2672 6.5 9.99249 6.5Z" fill={Constants.colors.captionColor} />
                    </svg>

                </Pressable>
            </View>
        </View >
        <View style={[styles.flexRow, styles.bottomRow]}>
            <Text style={[Constants.styles.badge, styles.itemLastFill]}>Last Fill: {props.lastFill}</Text>
            <Text style={[Constants.styles.badge, styles.itemFirstFill]}>First Fill: {props.firstFill}</Text>
            {props.dosage ? <Text style={[Constants.styles.badge, styles.itemDosage]}>Dosage: {props.dosage}</Text> : null}
        </View>
    </View>);
}

export function ConditionDisplay(props) {
    // Add testID to the root View
    const testProps = { testID: "cdisp", type: "Condition" };

    const widthForDisplayItem = 'clamp(70px,20vw,175px)';
    const maxWidthForDisplayItem = 'clamp(70px,20vw,175px)';
    const styles = new StyleSheet.create({
        flexRow: {
            flex: 1,
            flexDirection: 'row'
        },
        itemName: {
            fontWeight: '700',
            marginRight: 55,
            maxWidth: widthForDisplayItem,
            marginTop: 30,
            marginBottom: 30,
            minWidth: widthForDisplayItem,
            alignSelf: 'center',
            color: Constants.colors.textColor,
        },
        itemUse: {
            alignSelf: 'center',
            fontWeight: '500',
            color: Constants.colors.captionColor,
            maxWidth: maxWidthForDisplayItem,
            marginTop: 30,
            marginBottom: 30,
            minWidth: widthForDisplayItem,
        },
        itemNameMobile: {
            fontWeight: '700',
            marginRight: 55,
            marginTop: 30,
            marginBottom: 15,
            color: Constants.colors.textColor,
        },
        itemUseMobile: {
            fontWeight: '500',
            marginBottom: 25,
            color: Constants.colors.captionColor,
        },
        displayCard: {
            minHeight: 96,
            maxHeight: 'fit-contents',
            borderBottomWidth: 1,
            borderColor: Constants.colors.gray,
            marginLeft: Constants.leftOffset * -1,
            paddingLeft: Constants.leftOffset,
            opacity: props.openItemWizard ? .3 : 1,
            zIndex: -4,
            overflow: 'hidden',
        },
        topRow: {
            alignItems: 'center',
        },
        bottomRow: {
            display: 'none',
            marginBottom: 20,
        },
        itemLastFill: {
            marginRight: 14.75,
        },
        itemFirstFill: {
            marginRight: 14.75,
        },
        icon: {
            height: 16,
            width: 16,
        },
        idx: {
            fontSize: 63,
            fontWeight: '700',
            lineHeight: 76.24,
            maxHeight: 96,
            marginTop: 0,
            opacity: .1,
            position: 'absolute',
            marginLeft: -30 + 8, // 8px from the left padding
            paddingLeft: 0,
            color: Constants.colors.primaryColorBG,
        },
        idxMobile: {
            fontSize: 63,
            fontWeight: '700',
            lineHeight: 76.24,
            maxHeight: 96,
            marginTop: -45,
            opacity: .1,
            position: 'absolute',
            textAlign: 'left',
            width: 'calc(100% - 30px)',
            paddingLeft: 0,
            color: Constants.colors.primaryColorBG,
        }
    });


    let [name, setName] = useState(props.name);
    hyphenate(props.name).then(result => setName(result));

    let isMobile = useWindowDimensions().width < Constants.mobileMenuBreakpoint;

    return <Display {...props} part1={name} part2={props.meds} testProps={testProps} />


    let mobileDisplay = (<View style={styles.displayCard}>
        <View style={[styles.flexRow, styles.topRow, { justifyContent: 'space-between' }]}>
            <Text style={styles.idxMobile}>{props.id}</Text>
            <View style={styles.flex}>
                <Text style={styles.itemNameMobile}>{name}</Text>
                <Text style={styles.itemUseMobile}>{props.meds ? props.meds : null}</Text>
                <View style={[styles.flexRow, { flexDirection: 'row-reverse', marginRight: 30, justifyContent: 'space-between', marginBottom: 30 }]}>
                    <Pressable onPress={() => {
                        if (props.openItemWizard) return;

                        props.setOpenItem(props.uuid); props.setOpenItemWizard(true);
                    }} style={[styles.icon, { marginRight: 20 }]} >
                        <svg width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0956 0.58653L10.5816 2.10056L14.6482 6.16717L16.1622 4.65314C16.9443 3.8711 16.9443 2.60419 16.1622 1.82215L14.9297 0.58653C14.1477 -0.19551 12.8808 -0.19551 12.0987 0.58653H12.0956ZM9.87462 2.80752L2.58288 10.1024C2.25756 10.4277 2.01982 10.8313 1.88843 11.2723L0.781065 15.0355C0.702861 15.3014 0.774808 15.5861 0.968754 15.78C1.1627 15.9739 1.44736 16.0459 1.71013 15.9708L5.4733 14.8635C5.91437 14.7321 6.31791 14.4943 6.64324 14.169L13.9412 6.87413L9.87462 2.80752Z"
                                fill={Constants.colors.linkColor} />
                        </svg>
                    </Pressable>
                    <Pressable style={[styles.icon]} onPress={() => {
                        props.setDeleteItem(props.uuid);
                    }}>
                        <svg width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.22296 1.05313C4.39155 0.7125 4.73809 0.5 5.11586 0.5H8.8748C9.25257 0.5 9.59911 0.7125 9.7677 1.05313L9.99249 1.5H12.9897C13.5423 1.5 13.9887 1.94687 13.9887 2.5C13.9887 3.05313 13.5423 3.5 12.9897 3.5H1.00101C0.448405 3.5 0.00195312 3.05313 0.00195312 2.5C0.00195312 1.94687 0.448405 1.5 1.00101 1.5H3.99817L4.22296 1.05313ZM1.00101 4.5H12.9897V14.5C12.9897 15.6031 12.0936 16.5 10.9915 16.5H2.99911C1.89703 16.5 1.00101 15.6031 1.00101 14.5V4.5ZM3.99817 6.5C3.72343 6.5 3.49864 6.725 3.49864 7V14C3.49864 14.275 3.72343 14.5 3.99817 14.5C4.27291 14.5 4.4977 14.275 4.4977 14V7C4.4977 6.725 4.27291 6.5 3.99817 6.5ZM6.99533 6.5C6.72059 6.5 6.4958 6.725 6.4958 7V14C6.4958 14.275 6.72059 14.5 6.99533 14.5C7.27007 14.5 7.49486 14.275 7.49486 14V7C7.49486 6.725 7.27007 6.5 6.99533 6.5ZM9.99249 6.5C9.71775 6.5 9.49297 6.725 9.49297 7V14C9.49297 14.275 9.71775 14.5 9.99249 14.5C10.2672 14.5 10.492 14.275 10.492 14V7C10.492 6.725 10.2672 6.5 9.99249 6.5Z" fill={Constants.colors.captionColor} />
                        </svg>

                    </Pressable>
                </View>
            </View>

        </View >
        <View style={[styles.flexRow, styles.bottomRow]}>
            <Text style={[Constants.styles.badge, styles.itemLastFill]}>Last Treatment: {props.lastTreatment}</Text>
            <Text style={[Constants.styles.badge, styles.itemFirstFill]}>Dx: {props.wasDiagnosed}</Text>
        </View>
    </View>);

    let nonMobileDisplay = (<View style={styles.displayCard}>
        <View style={[styles.flexRow, styles.topRow, { justifyContent: 'space-between' }]}>
            <Text style={styles.idx}>{props.id}</Text>
            <View style={styles.flexRow}>
                <Text style={styles.itemName}>{name}</Text>
                <Text style={styles.itemUse}>{props.meds ? props.meds : null}</Text>
            </View>
            <View style={[{ display: 'flex', flexDirection: 'row', marginRight: 60, }]}>
                <Pressable onPress={() => {
                    if (props.openItemWizard) return;

                    props.setOpenItem(props.uuid); props.setOpenItemWizard(true);
                }} style={[styles.icon, { marginRight: 20 }]} >
                    <svg width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0956 0.58653L10.5816 2.10056L14.6482 6.16717L16.1622 4.65314C16.9443 3.8711 16.9443 2.60419 16.1622 1.82215L14.9297 0.58653C14.1477 -0.19551 12.8808 -0.19551 12.0987 0.58653H12.0956ZM9.87462 2.80752L2.58288 10.1024C2.25756 10.4277 2.01982 10.8313 1.88843 11.2723L0.781065 15.0355C0.702861 15.3014 0.774808 15.5861 0.968754 15.78C1.1627 15.9739 1.44736 16.0459 1.71013 15.9708L5.4733 14.8635C5.91437 14.7321 6.31791 14.4943 6.64324 14.169L13.9412 6.87413L9.87462 2.80752Z"
                            fill={Constants.colors.linkColor} />
                    </svg>
                </Pressable>
                <Pressable style={[styles.icon]} onPress={() => {
                    props.setDeleteItem(props.uuid);
                }}>
                    <svg width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.22296 1.05313C4.39155 0.7125 4.73809 0.5 5.11586 0.5H8.8748C9.25257 0.5 9.59911 0.7125 9.7677 1.05313L9.99249 1.5H12.9897C13.5423 1.5 13.9887 1.94687 13.9887 2.5C13.9887 3.05313 13.5423 3.5 12.9897 3.5H1.00101C0.448405 3.5 0.00195312 3.05313 0.00195312 2.5C0.00195312 1.94687 0.448405 1.5 1.00101 1.5H3.99817L4.22296 1.05313ZM1.00101 4.5H12.9897V14.5C12.9897 15.6031 12.0936 16.5 10.9915 16.5H2.99911C1.89703 16.5 1.00101 15.6031 1.00101 14.5V4.5ZM3.99817 6.5C3.72343 6.5 3.49864 6.725 3.49864 7V14C3.49864 14.275 3.72343 14.5 3.99817 14.5C4.27291 14.5 4.4977 14.275 4.4977 14V7C4.4977 6.725 4.27291 6.5 3.99817 6.5ZM6.99533 6.5C6.72059 6.5 6.4958 6.725 6.4958 7V14C6.4958 14.275 6.72059 14.5 6.99533 14.5C7.27007 14.5 7.49486 14.275 7.49486 14V7C7.49486 6.725 7.27007 6.5 6.99533 6.5ZM9.99249 6.5C9.71775 6.5 9.49297 6.725 9.49297 7V14C9.49297 14.275 9.71775 14.5 9.99249 14.5C10.2672 14.5 10.492 14.275 10.492 14V7C10.492 6.725 10.2672 6.5 9.99249 6.5Z" fill={Constants.colors.captionColor} />
                    </svg>

                </Pressable>
            </View>
        </View >
        <View style={[styles.flexRow, styles.bottomRow]}>
            <Text style={[Constants.styles.badge, styles.itemLastFill]}>Last Treatment: {props.lastTreatment}</Text>
            <Text style={[Constants.styles.badge, styles.itemFirstFill]}>Dx: {props.wasDiagnosed}</Text>
        </View>
    </View>);

    if (isMobile) return mobileDisplay;
    else return nonMobileDisplay;
}


function Display(props) {

    const widthForDisplayItem = 'clamp(70px,20vw,175px)';
    const maxWidthForDisplayItem = 'clamp(70px,20vw,175px)';
    const styles = new StyleSheet.create({
        flexRow: {
            flex: 1,
            flexDirection: 'row'
        },
        itemName: {
            fontWeight: '700',
            marginRight: 55,
            maxWidth: widthForDisplayItem,
            marginTop: 30,
            marginBottom: 30,
            minWidth: widthForDisplayItem,
            alignSelf: 'center',
            color: Constants.colors.textColor,
        },
        itemUse: {
            alignSelf: 'center',
            fontWeight: '500',
            color: Constants.colors.captionColor,
            maxWidth: maxWidthForDisplayItem,
            marginTop: 20,
            marginBottom: 30,
            minWidth: widthForDisplayItem,
        },
        itemNameMobile: {
            fontWeight: '700',
            marginRight: 0,
            marginTop: 30,
            marginBottom: 8,
            marginLeft: 30,
            color: Constants.colors.textColor,
        },
        itemUseMobile: {
            fontWeight: '500',
            marginBottom: 25,
            color: Constants.colors.captionColor,
            marginLeft: 30,
        },
        displayCard: {
            minHeight: 96,
            maxHeight: 'fit-contents',
            borderBottomWidth: 1,
            borderColor: Constants.colors.gray,
            marginLeft: Constants.leftOffset * -1,
            paddingLeft: Constants.leftOffset,
            opacity: props.openItemWizard ? .3 : 1,
            zIndex: -4,
            overflow: 'hidden',
        },
        topRow: {
            alignItems: 'center',
        },
        bottomRow: {
            display: 'none',
            marginBottom: 20,
        },
        itemLastFill: {
            marginRight: 14.75,
        },
        itemFirstFill: {
            marginRight: 14.75,
        },
        icon: {
            height: 16,
            width: 16,
        },
        idx: {
            fontSize: 63,
            fontWeight: '700',
            lineHeight: 76.24,
            maxHeight: 96,
            marginTop: 0,
            opacity: .1,
            position: 'absolute',
            marginLeft: -1 * Constants.leftOffset + 8, // 8px from the left padding
            paddingLeft: 0,
            color: Constants.colors.primaryColorBG,
        },
        idxMobile: {
            fontSize: 63,
            fontWeight: '700',
            lineHeight: 76.24,
            maxHeight: 96,
            marginTop: -45,
            opacity: .1,
            position: 'absolute',
            marginLeft: -1 * Constants.leftOffset + 8, // 8px from the left padding
            paddingLeft: 0,
            color: Constants.colors.primaryColorBG,
        }
    });


    let [name, setName] = useState(props.name);
    hyphenate(props.name).then(result => setName(result));

    let isMobile = useWindowDimensions().width < Constants.mobileMenuBreakpoint;


    let mobileDisplay = (<View {...props.testProps} style={styles.displayCard}>
        <View style={[styles.flexRow, styles.topRow, { justifyContent: 'space-between', width: '90vw', paddingRight: Constants.leftOffset }]}>
            <Text style={styles.idxMobile}>{props.id}</Text>
            <View style={[styles.flex, { width: '90vw', paddingRight: Constants.leftOffset }]}>
                <Text style={styles.itemNameMobile}>{props.part1}</Text>
                <Text style={styles.itemUseMobile}>{props.part2 ? props.part2 : null}</Text>
                {props.isRegistered && !props.readonly ?
                    <View style={[styles.flexRow, { marginRight: 0, justifyContent: 'space-between', marginBottom: 30 }]}>

                        <Pressable style={[styles.icon]} onPress={() => {
                            props.setDeleteItem(props.uuid);
                        }}>
                            <svg role="button" aria-label={`Delete ${props.testProps.type}`} width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.22296 1.05313C4.39155 0.7125 4.73809 0.5 5.11586 0.5H8.8748C9.25257 0.5 9.59911 0.7125 9.7677 1.05313L9.99249 1.5H12.9897C13.5423 1.5 13.9887 1.94687 13.9887 2.5C13.9887 3.05313 13.5423 3.5 12.9897 3.5H1.00101C0.448405 3.5 0.00195312 3.05313 0.00195312 2.5C0.00195312 1.94687 0.448405 1.5 1.00101 1.5H3.99817L4.22296 1.05313ZM1.00101 4.5H12.9897V14.5C12.9897 15.6031 12.0936 16.5 10.9915 16.5H2.99911C1.89703 16.5 1.00101 15.6031 1.00101 14.5V4.5ZM3.99817 6.5C3.72343 6.5 3.49864 6.725 3.49864 7V14C3.49864 14.275 3.72343 14.5 3.99817 14.5C4.27291 14.5 4.4977 14.275 4.4977 14V7C4.4977 6.725 4.27291 6.5 3.99817 6.5ZM6.99533 6.5C6.72059 6.5 6.4958 6.725 6.4958 7V14C6.4958 14.275 6.72059 14.5 6.99533 14.5C7.27007 14.5 7.49486 14.275 7.49486 14V7C7.49486 6.725 7.27007 6.5 6.99533 6.5ZM9.99249 6.5C9.71775 6.5 9.49297 6.725 9.49297 7V14C9.49297 14.275 9.71775 14.5 9.99249 14.5C10.2672 14.5 10.492 14.275 10.492 14V7C10.492 6.725 10.2672 6.5 9.99249 6.5Z" fill={Constants.colors.captionColor} />
                            </svg>

                        </Pressable>

                        <Pressable onPress={() => {

                            if (props.openItemWizard) return;

                            props.setOpenItem(props.uuid); props.setOpenItemWizard(true)
                        }} style={[styles.icon, { marginRight: 30 }]} >
                            <svg role="button" aria-label={`Edit ${props.testProps.type}`} width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0956 0.58653L10.5816 2.10056L14.6482 6.16717L16.1622 4.65314C16.9443 3.8711 16.9443 2.60419 16.1622 1.82215L14.9297 0.58653C14.1477 -0.19551 12.8808 -0.19551 12.0987 0.58653H12.0956ZM9.87462 2.80752L2.58288 10.1024C2.25756 10.4277 2.01982 10.8313 1.88843 11.2723L0.781065 15.0355C0.702861 15.3014 0.774808 15.5861 0.968754 15.78C1.1627 15.9739 1.44736 16.0459 1.71013 15.9708L5.4733 14.8635C5.91437 14.7321 6.31791 14.4943 6.64324 14.169L13.9412 6.87413L9.87462 2.80752Z"
                                    fill={Constants.colors.linkColor} />
                            </svg>
                        </Pressable>

                    </View> :
                    <View style={[styles.flexRow, { marginRight: 0, justifyContent: 'space-between', marginBottom: 30 }]}>
                        <Pressable onPress={() => {
                            if (props.openItemWizard) return;

                            props.setOpenItem(props.uuid); props.setOpenItemWizard(true)
                        }} style={[styles.icon, { marginRight: 30 }]} >
                            <Icon aria-label={`View ${props.testProps.type}`} role="button" as={AntDesign} name="folderopen" color={Constants.colors.linkColor} style={[styles.icon, { marginLeft: 0 }]} />
                        </Pressable>
                    </View>
                }
            </View>

        </View >
        <View style={[styles.flexRow, styles.bottomRow]}>
            <Text style={[Constants.styles.badge, styles.itemLastFill]}>Last Treatment: {props.lastTreatment}</Text>
            <Text style={[Constants.styles.badge, styles.itemFirstFill]}>Dx: {props.wasDiagnosed}</Text>
        </View>
    </View>);

    let nonMobileDisplay = (<View {...props.testProps} style={styles.displayCard}>
        <View style={[styles.flexRow, styles.topRow, { justifyContent: 'space-between' }]}>
            <Text style={styles.idx}>{props.id}</Text>
            <View style={styles.flexRow}>
                <Text style={styles.itemName}>{props.part1}</Text>
                <Text style={styles.itemUse}>{props.part2 ? props.part2 : null}</Text>
            </View>
            {props.isRegistered && !props.readonly ?
                <View style={[{ display: 'flex', flexDirection: 'row' }]}>
                    <Pressable style={[styles.icon, { marginRight: 20 }]} onPress={() => {
                        props.setDeleteItem(props.uuid);
                    }}>
                        <svg role="button" aria-label={`Delete ${props.testProps.type}`} width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            l
                            <path d="M4.22296 1.05313C4.39155 0.7125 4.73809 0.5 5.11586 0.5H8.8748C9.25257 0.5 9.59911 0.7125 9.7677 1.05313L9.99249 1.5H12.9897C13.5423 1.5 13.9887 1.94687 13.9887 2.5C13.9887 3.05313 13.5423 3.5 12.9897 3.5H1.00101C0.448405 3.5 0.00195312 3.05313 0.00195312 2.5C0.00195312 1.94687 0.448405 1.5 1.00101 1.5H3.99817L4.22296 1.05313ZM1.00101 4.5H12.9897V14.5C12.9897 15.6031 12.0936 16.5 10.9915 16.5H2.99911C1.89703 16.5 1.00101 15.6031 1.00101 14.5V4.5ZM3.99817 6.5C3.72343 6.5 3.49864 6.725 3.49864 7V14C3.49864 14.275 3.72343 14.5 3.99817 14.5C4.27291 14.5 4.4977 14.275 4.4977 14V7C4.4977 6.725 4.27291 6.5 3.99817 6.5ZM6.99533 6.5C6.72059 6.5 6.4958 6.725 6.4958 7V14C6.4958 14.275 6.72059 14.5 6.99533 14.5C7.27007 14.5 7.49486 14.275 7.49486 14V7C7.49486 6.725 7.27007 6.5 6.99533 6.5ZM9.99249 6.5C9.71775 6.5 9.49297 6.725 9.49297 7V14C9.49297 14.275 9.71775 14.5 9.99249 14.5C10.2672 14.5 10.492 14.275 10.492 14V7C10.492 6.725 10.2672 6.5 9.99249 6.5Z" fill={Constants.colors.captionColor} />
                        </svg>

                    </Pressable>
                    <Pressable onPress={() => {
                        if (props.openItemWizard) return;

                        props.setOpenItem(props.uuid); props.setOpenItemWizard(true);
                    }} style={[styles.icon, { marginRight: 60 }]} >
                        <svg width={Constants.fontSizes.iconSize} height={Constants.fontSizes.iconSize} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path role='button' aria-label={`Edit ${props.testProps.type}`} d="M12.0956 0.58653L10.5816 2.10056L14.6482 6.16717L16.1622 4.65314C16.9443 3.8711 16.9443 2.60419 16.1622 1.82215L14.9297 0.58653C14.1477 -0.19551 12.8808 -0.19551 12.0987 0.58653H12.0956ZM9.87462 2.80752L2.58288 10.1024C2.25756 10.4277 2.01982 10.8313 1.88843 11.2723L0.781065 15.0355C0.702861 15.3014 0.774808 15.5861 0.968754 15.78C1.1627 15.9739 1.44736 16.0459 1.71013 15.9708L5.4733 14.8635C5.91437 14.7321 6.31791 14.4943 6.64324 14.169L13.9412 6.87413L9.87462 2.80752Z"
                                fill={Constants.colors.linkColor} />
                        </svg>
                    </Pressable>

                </View> : <View style={[{ display: 'flex', flexDirection: 'row' }]}>
                    <Pressable onPress={() => {
                        if (props.openItemWizard) return;

                        props.setOpenItem(props.uuid); props.setOpenItemWizard(true);
                    }} style={[styles.icon, { marginRight: 60 }]} >
                        <Icon aria-label={`View ${props.testProps.type}`} role="button" as={AntDesign} name="folderopen" color={Constants.colors.linkColor} style={[styles.icon, { marginLeft: 0 }]} />
                    </Pressable>
                </View>}
        </View >
        <View style={[styles.flexRow, styles.bottomRow]}>
            <Text style={[Constants.styles.badge, styles.itemLastFill]}>Last Treatment: {props.lastTreatment}</Text>
            <Text style={[Constants.styles.badge, styles.itemFirstFill]}>Dx: {props.wasDiagnosed}</Text>
        </View>
    </View >);

    if (isMobile) return mobileDisplay;
    else return nonMobileDisplay;
}