// src/Input.js

import React, { useState, useRef, useEffect } from 'react'
import { View, StyleSheet, TextInput, Pressable, Platform, useWindowDimensions, Dimensions } from 'react-native'
import * as Constants from '../theme'
import { AntDesign, FontAwesome, Feather } from '@expo/vector-icons';


import MaskInput, { Masks } from 'react-native-mask-input';
import { Select, CheckIcon, ChevronDownIcon, Radio, Icon, IconButton } from "native-base";

import * as Data from './libs/data';
import Text from './Text';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { style } from '@mui/system';
import { hyphenate } from 'hyphen/en';
function TextInputField(props) {
    const [inputVal, setInputVal] = useState('');
    const [prevVal, setPrevVal] = useState('');
    const [skipChange, setSkipChange] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [inAutocomplete, setInAutocomplete] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const autocompleteDisplayLimit = Constants.autocompleteMaxOptions;
    const inputFieldRef = useRef(null);
    const [shouldFocus, setShouldFocus] = useState(false);
    const [autocompleteHighlight, setAutocompleteHighlight] = useState(-1);
    const [forceCloseAutocomplete, setForceCloseAutocomplete] = useState('');
    const [hideAutocomplete, setHideAutocomplete] = useState(false);
    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [isEditingField, setIsEditingField] = useState(false);

    const renderCount = useRef(0);

    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let mobileWidth = (Dimensions.get('window').width - 120);
    if (Dimensions.get('window').width <= 360) mobileWidth = '80vw'
    let controlMaxWidth = isMobile ? mobileWidth : Constants.controlMaxWidth;
    if (props.style && props.style.hasOwnProperty('width')) {
        controlMaxWidth = isMobile ? mobileWidth :
            (props.width ? props.width : props.style.width);
    }
    useEffect(() => {
        if (props.activeAutocompleteID) {

            if (isFocused || inAutocomplete) {
                props.setActiveAutocomplete(props.activeAutocompleteID);

            } else {
                if (props.activeAutocomplete == props.activeAutocompleteID) {
                    props.setActiveAutocomplete('');
                }
            }
        }
    }, [isFocused])

    useEffect(() => {
        renderCount.current++;
    })
    useEffect(() => {
        if (props.value) {
            setInputVal(props.value);
        } else if (props.getter) {
            setInputVal(props.getter);
        }

        if (props.getter) {
            if (props.hiddenType == 'orderid') {
                setInputVal(props.getter.replace(/[^\-]/g, '*'));
            } else if (props.hiddenType == 'email') {
                let parts = props.getter.split(/@/);
                [0, 1].forEach(function (idx) {
                    let firstchar = parts[idx][0];
                    parts[idx] = firstchar + parts[idx].replace(/./g, '*').substr(1);
                });
                setInputVal(parts.join('@'));
            }
        }
    }, [props.getter]);

    useEffect(() => {
        // @TODO, this breaks safari usability
        //if (props.setter) props.setter(inputVal);
    }, [inputVal])

    useEffect(() => {
        if (props.setEraseField && props.eraseField) {
            setInputVal('');
            props.setEraseField(false)
            setShouldFocus(true);
            if (props.onErase) props.onErase();
        }
    }, [props.eraseField])

    const autocorrectField = (newVal, previous, basedOnKeyup) => {
        if (!(props.correctTypos && (previous ? previous.length < newVal.length : true))) {
            return newVal;
        }
        if (!newVal) return newVal;

        let words = newVal.toUpperCase().split(/\s+/);
        var new_arr = [];
        var added_indices = [];

        let changed = false;
        for (var num_words = 0; num_words < words.length; num_words++) {
            // looking for adjacent words
            for (var i = 0; i < words.length; i++) {
                var word = '';
                var to_add = [];
                for (var n = 0; n <= num_words; n++) {
                    if ((i + n) < words.length) {
                        to_add.push(words[i + n]);
                    }
                }

                // now that we have the adjacent words, join them into a string
                word = to_add.join(' ');

                if (props.correctTypos[word] != undefined) {
                    let original = word;
                    if (basedOnKeyup) {
                        if (props.correctTypos[word].toUpperCase().includes(word) && props.correctTypos[word].length > word.length) {
                            /* do nothing.
                             * this is to prevent "ASTHM" from becoming "ASTHMA" **while** someone is typing
                             */
                            continue;
                        } else {
                            word = props.correctTypos[word];
                            changed = true;
                        }
                    } else {
                        /* fix things like HIGH CHOLESTEROL => HIGH HIGH CHOLESTEROL
                         * by checking to see if the length of the typo's correction
                         * has essentially already been made by the user.  We want
                         * CHOLESTEROL => HIGH CHOLESTEROL, but HIGH CHOLESTEROL to not change
                         * ... I don't think if the substring is in there multiple times and one
                         * time it's wrong will be a real world problem, so we can do the easy way
                         * of just checking if the corrected string exists already
                        */

                        if (!newVal.toUpperCase().includes(props.correctTypos[word])) {
                            word = props.correctTypos[word];
                            changed = true;
                        }
                    }

                    // saw which words we updated (we may have updated a group)
                    if (changed) {
                        for (var n = 0; n <= num_words; n++) {
                            if ((i + n) < words.length) {
                                added_indices.push(i + n); // note that we found the elems and their indices
                            }
                        }
                    }

                    if (original != word) {
                        new_arr[i] = word;
                        num_words += to_add.length - 1; // num_words ++ runs after this iteration
                    }
                }
            }
        }

        for (var i = 0; i < words.length; i++) {
            if (new_arr[i] == undefined && added_indices.indexOf(i) == -1) {
                new_arr[i] = words[i];
            }
        }

        newVal = new_arr.join(' ').split(/\s+/).join(' ');

        return newVal;
    }

    const resetAutocomplete = () => {
        setAutocompleteHighlight(-1);
        setAutocompleteOpen(false);
        return;
    }

    const AutocompleteOptions = () => {
        if (autocompleteOptions.length == 0) return resetAutocomplete();
        if (inputVal && autocompleteOptions.includes(inputVal.trim().toUpperCase()) && autocompleteOptions.length == 1) {
            setTimeout(() => {
                if (props.nextFieldFocuser) {
                    try {
                        props.nextFieldFocuser(option);
                    } catch (e) { }
                    if (props.focusAfterAutocomplete) {
                        inputFieldRef.current.focus();
                    }
                }
            }, 500);
            setInputVal(inputVal.trim().toUpperCase());
            return resetAutocomplete();
        }
        if (inputVal && inputVal.trim() == '') return resetAutocomplete();
        if (!isFocused && !inAutocomplete) return resetAutocomplete();
        if (forceCloseAutocomplete == inputVal) return resetAutocomplete();
        if (props.optionsNarrowed) return resetAutocomplete();
        //if (autocompleteOptions.filter(function (x) { return x.startsWith(inputVal) }).length == 1) return resetAutocomplete();
        if (props.hideAutocomplete) { setInAutocomplete(false); setHideAutocomplete(false); return resetAutocomplete(); }
        setAutocompleteOpen(true);
        return (<View style={styles.autocompleteWrap} onMouseEnter={() => { setInAutocomplete(true) }} onMouseLeave={() => { setInAutocomplete(false) }}>
            {autocompleteOptions.slice(0, autocompleteDisplayLimit).map((option, idx) => {
                return (
                    <Pressable style={[(autocompleteHighlight != -1) ? ((autocompleteHighlight == idx) ? styles.highlightAutocompleteOption : styles.unhighlightAutocompleteOptionText) : null]}
                        onMouseUp={(e) => {
                            setInputVal(option);
                            setForceCloseAutocomplete(option);
                            // @TODO this breaks safari
                            if (props.setter) props.setter(option);
                            if (props.onAutocompleteSelect) {

                                setForceCloseAutocomplete(option);
                                setHideAutocomplete(true);
                                props.onAutocompleteSelect(option);
                                renderCount.current++;
                                try {
                                    if (props.focusAfterAutocomplete) {
                                        inputFieldRef.current.focus();
                                    }
                                } catch (e) { }
                                if (props.nextFieldFocuser) {
                                    try {
                                        props.nextFieldFocuser(option);
                                    } catch (e) { }
                                }
                            }
                            if (props.focusAfterAutocomplete) { try { inputFieldRef.current.focus() } catch (e) { } }
                            e.preventDefault();
                        }}
                        onPressOut={(e) => {
                            setInputVal(option);
                            setForceCloseAutocomplete(option);
                            // @TODO this breaks safari
                            if (props.setter) props.setter(option);
                            if (props.onAutocompleteSelect) {

                                setForceCloseAutocomplete(option);
                                setHideAutocomplete(true);
                                props.onAutocompleteSelect(option);
                                renderCount.current++;
                                try {
                                    if (props.focusAfterAutocomplete) {
                                        inputFieldRef.current.focus();
                                    }
                                } catch (e) { }
                                if (props.nextFieldFocuser) {
                                    try {
                                        props.nextFieldFocuser();
                                    } catch (e) { }
                                }
                            }
                            if (props.focusAfterAutocomplete) { try { inputFieldRef.current.focus() } catch (e) { } }
                            e.preventDefault();
                        }}
                    >
                        <Text
                            style={[styles.autocompleteOption, (autocompleteHighlight != -1) ? ((autocompleteHighlight == idx) ? styles.highlightAutocompleteOption : null, autocompleteHighlight != idx ? styles.unhighlightAutocompleteOptionText : null) : null]}>
                            {option}
                        </Text>
                    </Pressable>)
            })
            }
        </View >);
    }

    const ShowChips = () => {
        if (!props.chips) return;
        if (props.chips === undefined) return;
        if (props.chips.length == 0) return;
        return (
            <View key={`chip-holder-${renderCount.current}`}>
                <View style={[Constants.styles.flexRow, { maxWidth: controlMaxWidth, flexWrap: 'wrap' }]}>
                    {props.chips.map(function (c, index) {
                        let chip = c.replace(/\s/g, Constants.nbsp)
                        return <Pressable
                            onPress={(e) => {

                                let x = [...props.chips];
                                x.splice(index, 1);
                                x = x.filter(elem => elem);
                                props.setChips(x);
                                try {
                                    if (props.focusAfterAutocomplete) {
                                        inputFieldRef.current.focus()
                                    }
                                } catch (e) {

                                }


                                if (props.onChipDelete) props.onChipDelete(e, x);

                            }}
                            style={[Constants.styles.badge, styles.chip, { backgroundColor: Constants.colors.primaryButton, color: Constants.colors.primaryColorText, flex: 1, flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between', paddingRight: 8 }]}>
                            <Text style={[styles.chip, { padding: 0, margin: 0, backgroundColor: 'transparent', color: Constants.colors.primaryColorText }]}>{chip}</Text>
                            <Icon as={AntDesign} name='close' size={2} style={{ color: Constants.colors.primaryColorText, marginLeft: 5 }} />
                        </Pressable>

                    })
                    }
                </View>
            </View >
        )
    };

    const inputStyle = [
        { color: Constants.colors.textColor },
        props.style,
        { borderWidth: 1, borderColor: Constants.colors.inputBorder, paddingLeft: 14.5, paddingRight: 14.5, width: controlMaxWidth },
        ((isFocused || isHover) && { borderRadius: 0, borderColor: Constants.colors.primaryColorBG, outline: 'none', boxShadow: 'none' })
    ];
    const dateInput = (
        <MaskInput
            value={inputVal}
            onMouseUp={(e) => {
                if (e.target.selectionStart < inputVal.length) {
                    setIsEditingField(true);
                    return;
                } else {
                    setIsEditingField(false);
                }
            }}
            onKeyUp={(e) => {

                if (e.target.selectionStart < inputVal.length) {
                    setIsEditingField(true);
                    // if the key is alpha, don't process the key
                    if (e.key.match(/[a-zA-Z]/)) {
                        return e.preventDefault();
                    }
                } else {
                    setIsEditingField(false);
                }

                // @TODO need to add support for pasting in DOBs that aren't in MM/DD/YYYY format

                if (['2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
                    // this should only happen for the Month and Day.

                    // month - only if blank or (after keyup, 1 char)
                    if (e.target.value == '') {

                        e.target.value += '0' + e.key + '/';
                    } else {

                        // day

                        // if we are currently in the "day" part of the date
                        // meaning we end with a slash or could end with a slash
                        // note 3 or 4 is because we're calculating AFTER the mask gets applied
                        // so it should always be 4, but in the off change
                        if (e.target.value.length == 3 || e.target.value.length == 4) {
                            if (['4', '5', '6', '7', '8', '9'].includes(e.key)) {
                                e.target.value += '0' + e.key + '/';
                            }
                        }
                    }
                } else {
                    if (e.key == '/') {
                        if (e.target.value.length == 1) {
                            e.target.value = '0' + e.target.value + '/';
                        }
                    } else {
                    }
                }

                let parts = e.target.value.split('/');
                let save = true;
                if (parts.filter(a => a).length == 3) {
                    if (parts[2].length == '2') {
                        if (parts[2] == '19' || parts[2] == '20') return;
                        if (parseInt(parts[2]) >= parseInt(new Date().getFullYear().toString().substr(-2))) {
                            parts[2] = '19' + parts[2];
                            e.target.value = parts.join('/');
                            if (props.setter) {
                                props.setter(parts.join('/'));
                                save = false;
                            }
                        } else if (parseInt(parts[2]) < parseInt(new Date().getFullYear().toString().substr(-2))) {
                            parts[2] = '20' + parts[2];
                            e.target.value = parts.join('/');
                            if (props.setter) {
                                props.setter(parts.join('/'));
                                save = false;
                            }
                        }
                    }
                } else {
                    save = false;
                }

                if (save) {
                    //props.setter(parts.join('/'));
                    inputFieldRef.current.value = parts.join('/');
                }

                if (props.onKeyUp) props.onKeyUp(e);

                // if the key is alpha, don't process the key
                if (e.key.match(/[a-zA-Z]/)) {
                    return e.preventDefault();
                }

                // refocus the input
                inputFieldRef.current.focus();


            }}
            onChangeText={(newVal) => {
                let previous = inputVal;
                try { props.onChangeText(newVal) } catch (e) { };

                if (props.chipInput) {
                    return processAutocompleteHighlight(e);
                }

                if (props.correctTypos) {
                    newVal = autocorrectField(newVal, previous, true);
                }

                newVal = newVal.replace(/  /g, ' ');


                setInputVal(newVal);

                // @TODO this breaks safari
                //if (props.setter) props.setter(newVal);
            }}
            mask={isEditingField ? '' : Masks.DATE_MMDDYYYY}
            onBlur={(e) => {
                setIsFocused(false);
                setIsEditingField(false);
                // check to see if we used a 2 digit year
                let parts = e.target.value.split('/');
                let save = true;
                if (parts.length == 3) {
                    if (parts[2].length == '2') {
                        if (parseInt(parts[2]) >= parseInt(new Date().getFullYear().toString().substr(-2))) {
                            parts[2] = '19' + parts[2];
                            e.target.value = parts.join('/');
                            if (props.setter) {
                                props.setter(parts.join('/'));
                                save = false;
                            }
                        } else if (parseInt(parts[2]) < parseInt(new Date().getFullYear().toString().substr(-2))) {
                            parts[2] = '20' + parts[2];
                            e.target.value = parts.join('/');
                            if (props.setter) {
                                props.setter(parts.join('/'));
                                save = false;
                            }
                        }
                    }
                }

                if (props.setter && save) props.setter(inputVal)

            }}
            onFocus={() => setIsFocused(true)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onKeyPress={(e) => { if (props.onKeyPress) return props.onKeyPress(e) }}
            nativeID={props.id}
            placeholder={props.placeholder}
            style={inputStyle}
            placeholderTextColor={Constants.colors.captionColor}
            ref={props.refVal ? props.refVal : (field) => inputFieldRef.current = field}
            autoFocus={props.autoFocus}
            keyboardType='number-pad'
            maxLength={props.maxLength}
            editable={props.hasOwnProperty('editable') ? props.editable : !props.readonly}
            disabled={props.disabled}
            testID={props.testID}
        />

    );
    const heightInput = (
        <TextInput
            testID={props.testID}
            maxLength={`10'10"`.length}
            accessibilityLabel={props.ariaLabel}
            accessibilityLabelledby={props.ariaLabelledBy}
            ref={(field) => inputFieldRef.current = field}
            value={inputVal}
            onFocus={() => setIsFocused(true)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onBlur={(event) => {
                setIsFocused(false)
                let masked = event.nativeEvent.text;
                if (masked) {
                    if (masked.replace(/\D/g, '').length == 0) {
                        return;
                    }
                    masked = masked.replace(/'"/, `'`);
                    setInputVal(masked);
                    let ft = masked.replace(/'.*/, '').replace(/\D/g, '');
                    // ft should be the min of ft and 10
                    ft = Math.min(ft, 9);
                    let inches = masked.replace(/.*'/, '').replace(/\D/g, '');
                    inches = Math.min(inches, 11);
                    // make sure inches is 2 digits

                    if (`${inches}`.length == 1) {
                        inches = '0' + inches;
                    }
                    if (masked.includes("'")) {
                        if (inches.length == 1) {
                            inches = '0' + inches;
                        }
                        setInputVal(`${ft}'${inches}"`);
                        event.target.value = `${ft}'${inches}"`;
                        if (props.setter) props.setter(`${ft}'${inches}"`);
                    } else {
                        setInputVal(`${ft}'0"`)
                        event.target.value = `${ft}'0"`;
                        if (props.setter) props.setter(`${ft}'0"`);
                    }
                } else {
                    let val = event.target.value;
                    val = val.replace(/'"/, `'`);
                    if (props.setter) props.setter(val);
                    event.target.value = val;
                }

                let val = event.target.value;
                val = val.replace(/'"*$/, `'0"`);
                event.target.value = val;
                setInputVal(val);
                if (props.setter) props.setter(val);
            }}
            onChangeText={(masked, unmasked) => {
                if (skipChange) {
                    setInputVal(masked);
                    setPrevVal(masked);
                    return;
                };
                if (masked.length != 1) {
                    if (prevVal.length > masked.length) {
                        setPrevVal(masked);
                        return setInputVal(masked);
                    }
                }
                if (masked.includes('"')) {
                    return;
                }
                if (masked.replace(/\D/g, '').length == 0) {
                    setPrevVal(masked);
                    return setInputVal(masked);
                }
                let newVal = masked;
                if (!masked.includes("'")) {
                    // if we start with zero, we don't want to add a single quote
                    if (masked.match(/^0/) && masked.replace(/0/g, '').length == 0) {
                        newVal = masked.replace(/^0+/, '0');
                    } else {
                        // if we start with zero, overwrite it
                        newVal = masked.replace(/^0/, '') + "'";
                    }
                } else {
                    if (masked.match(/'[01][01]/)) {
                        newVal = masked + '"';
                    } else {
                        if (masked.match(/'([2-9])/)) {
                            newVal = masked.replace(/'([2-9])/, `'0$1"`);
                        } else {
                            newVal = masked;
                        }
                    }
                }

                newVal = newVal.replace(/'"/, `'`);
                setInputVal(newVal);
                setPrevVal(newVal);
            }}
            nativeID={props.id}
            placeholder={props.placeholder ? props.placeholder : `0'0"`}
            onKeyPress={(e) => {
                const isCursorAtEnd = (e.target.selectionStart == e.target.value.length && e.target.selectionEnd == e.target.value.length);

                setSkipChange(!isCursorAtEnd);
                if (isCursorAtEnd) {
                    if (e.key == "'") {
                        // make sure we have 2 digits
                        if (e.target.value.replace(/\D/g, '').length != 2
                            || e.target.value.includes(`'`)) {
                            setSkipChange(false);
                            e.preventDefault();
                            return;
                        }
                        setSkipChange(true);
                        if (props.onKeyPress) return props.onKeyPress(e)
                        return;
                    }

                    if (e.key == '"') {
                        // make sure we have 2 digits
                        if (e.target.value.replace(/.*'/, '').replace(/\D/g, '').length != 2
                            || e.target.value.includes(`"`)) {
                            setSkipChange(false);
                            e.preventDefault();
                            return;
                        }
                        setSkipChange(true);
                        if (props.onKeyPress) return props.onKeyPress(e)
                        return;
                    }
                }

                if (e.key == 'Backspace') {
                    setPrevVal(e.target.value);
                    setInputVal(e.target.value);
                    setSkipChange(true);
                } else {
                    if (isCursorAtEnd) {
                        if (['2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
                            // if we have x'y and we press 2-9, it shouldn't work
                            if (e.target.value.match(/'[1-9][0-9]*"*/)) {
                                setSkipChange(false);
                                e.preventDefault();
                            }
                        }
                    }
                }

                if (props.onKeyPress) return props.onKeyPress(e)

            }}
            onKeyUp={(e) => {
                let cleanedValue = e.target.value.replace(/[^0-9'"]/g, '');

                if (cleanedValue !== e.target.value) {
                    e.target.value = cleanedValue;
                    setInputVal(cleanedValue);
                }

                if (props.onKeyUp) return props.onKeyUp(e)
            }}
            style={inputStyle}
            placeholderTextColor={Constants.colors.captionColor}
            keyboardType='numeric'
            editable={props.hasOwnProperty('editable') ? props.editable : !props.readonly}
            disabled={props.disabled}
        />
    );
    const orderidInput = (
        <MaskInput
            testID={props.testID}
            ref={(field) => inputFieldRef.current = field}
            value={inputVal}
            onFocus={() => setIsFocused(true)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            autoFocus={props.autoFocus}
            onBlur={(event) => {
                setIsFocused(false)
                let masked = event.nativeEvent.text;
                if (masked) {
                    setInputVal(masked);
                    if (props.setter) props.setter(masked);
                }
            }}
            onChangeText={(masked, unmasked) => {
                setInputVal(masked);
                // @TODO This breaks safari
                if (props.setter) {
                    try {
                        props.setter(masked.toUpperCase());
                    } catch (e) { }
                }
            }}
            nativeID={props.id}
            placeholder={props.placeholder}
            mask={(text) => {
                return [/./, /./, /./, "-", /./, /./, /./, '-', /./, /./, /./]
            }}
            style={inputStyle}
            placeholderTextColor={Constants.colors.captionColor}
            editable={props.hasOwnProperty('editable') ? props.editable : !props.readonly}
            disabled={props.disabled}
            maxLength={`XXX-YYY-ZZZ`.length}
        />
    );
    const numberInput = (
        <MaskInput
            testID={props.testID}
            ref={(field) => inputFieldRef.current = field}
            value={inputVal}
            accessibilityLabel={props.ariaLabel}
            accessibilityLabelledby={props.ariaLabelledBy}
            keyboardType='numeric'
            onBlur={() => { setIsFocused(false); if (props.setter) props.setter(inputVal) }}
            onFocus={() => setIsFocused(true)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onKeyDown={(e) => { if (props.onKeyDown) props.onKeyDown(e) }}
            onChangeText={(newVal) => {
                let previous = inputVal;
                try { props.onChangeText(newVal) } catch (e) { };

                if (props.chipInput) {
                    return processAutocompleteHighlight(e);
                }

                if (props.correctTypos) {
                    newVal = autocorrectField(newVal, previous, true);
                }

                newVal = newVal.replace(/\D/g, '');


                setInputVal(newVal);

                // @TODO This breaks safari
                // if (props.setter) props.setter(newVal);
            }}
            onKeyPress={(e) => { if (props.onKeyPress) return props.onKeyPress(e) }}
            onKeyUp={(e) => { if (props.onKeyUp) return props.onKeyUp(e) }}
            nativeID={props.id}
            placeholder={props.placeholder}
            style={inputStyle}
            maxLength={props.maxLength}
            placeholderTextColor={Constants.colors.captionColor}
            autoComplete={props.autoComplete}
            autoFocus={props.autoFocus}
            editable={props.hasOwnProperty('editable') ? props.editable : !props.readonly}
            disabled={props.disabled}
        />
    );
    let i;
    if (props.type == 'date') {
        i = dateInput;
    }
    if (props.type == 'number') {
        if (props.mask == 'height') {
            i = heightInput;
        } else {
            i = numberInput;
        }
    }
    if (props.type == 'orderid') {
        i = orderidInput;
    }

    /* if it's a number, height, or date */
    if (i) {
        if (props.prefixAdornment && i) {
            return (<View style={[{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }, props.iconStyleWrap]}>
                <Icon as={FontAwesome} name={props.prefixAdornment} color={Constants.colors.label} style={[styles.moreInfoIcon, { marginRight: -20, marginLeft: 3 /* looks like this is the number from analyzing...maybe based on widths...*/, zIndex: 5, paddingLeft: 5 }, props.iconStyle]} />
                {i}
            </View >)
        }
        if (props.postfixAdornment && i) {
            return (<View style={[{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }, props.iconStyleWrap]}>
                {i}
                <Icon as={FontAwesome} name={props.postfixAdornment} color={Constants.colors.label} style={[styles.moreInfoIcon, { marginLeft: -25, marginRight: 9 /* looks like this is the number from analyzing...maybe based on widths...*/, zIndex: 5, paddingRight: 5 }, props.iconStyle]} />
            </View >)
        }

        return i;
    }


    const processAutocompleteHighlight = (e) => {
        if (autocompleteOptions === undefined || autocompleteOptions.length < 1) return resetAutocomplete();

        if (props.autocomplete) {

            let direction = 0;
            if (e.key == 'ArrowDown') {
                direction = 1;
                e.preventDefault();
            }
            if (e.key == 'ArrowUp') {
                direction = -1;
                e.preventDefault();
            }
            let newHighlight = autocompleteHighlight + direction;
            if (direction != 0) {
                if (props.setAutocompleteEngaged) props.setAutocompleteEngaged(true);

                let maxLen = Constants.autocompleteMaxOptions;
                if (maxLen > autocompleteOptions.length) maxLen = autocompleteOptions.length;

                while ((newHighlight - maxLen) >= 0) {
                    newHighlight -= maxLen;
                }
                if (newHighlight < 0) { newHighlight = maxLen - Math.abs(newHighlight) };
                setAutocompleteHighlight(newHighlight);
            }


            if (e.key == 'Enter' || (props.chipInput && e.key == ',')) {

                setInputVal(autocompleteOptions[newHighlight]);
                try {
                    if (props.focusAfterAutocomplete) {
                        inputFieldRef.current.focus();
                    }
                } catch (e) { }
                setForceCloseAutocomplete(autocompleteOptions[newHighlight]);
                resetAutocomplete();
                if (props.setMedForCond) props.setMedForCond(autocompleteOptions[newHighlight]);
                if (props.nextFieldFocuser) {
                    try {
                        props.nextFieldFocuser();
                    } catch (e) { }
                }
                setForceCloseAutocomplete(inputVal);
                setHideAutocomplete(true);
                return autocompleteOptions[newHighlight];

                if (trySubmit) {
                    trySubmit.current.click();
                }
            }

            if (e.key == 'Escape') {
                if (autocompleteOpen) {
                    if (autocompleteHighlight == -1) {
                        setForceCloseAutocomplete(inputVal);
                        setHideAutocomplete(true);
                    }
                } else {
                    if (props.onEscape) props.onEscape(e);
                }
                return resetAutocomplete();
            }

        }

        setForceCloseAutocomplete('');
    }

    let keyCatcher = (e) => {
        if (!(props.autocomplete || props.chips)) return props.onKeyPress ? props.onKeyPress(e) : null;
        setHideAutocomplete(false);

        if (props.chips && props.autocomplete) {
            let autocompleted = processAutocompleteHighlight(e);

            if (e.key == 'Enter' || e.key == ',') {
                if (!autocompleted) {
                    autocompleted = inputVal;
                }
                let x = [...props.medChips, autocompleted].filter(element => element);

                props.setMedChips(x);
                props.setMedForCond('');
                e.target.value = '';
                //try { medNameFieldRef.current.focus(); } catch (e) { }
                props.setEraseField(true);

                e.preventDefault();
                setForceCloseAutocomplete('');
                setHideAutocomplete(true);

            }
            if (props.medForCond != '') {
                let toAdd = [...props.medChips, props.medForCond].filter(element => element);
                if (toAdd.length > 1 && toAdd[toAdd.length - 1] == toAdd[toAdd.length - 2]) {
                    toAdd.pop();
                }
                props.setMedicationsToProcess(toAdd);
            } else {
                props.setMedicationsToProcess([...props.medChips].filter(element => element))
            }
        }

        try {
            if (!props.chipInput && props.confirmAutocompleteBeforeSubmit) {
                let val = processAutocompleteHighlight(e);
                if (val !== undefined) {
                    if (props.setter) props.setter(val);
                    inputFieldRef.current.focus();
                    e.preventDefault();
                    return;
                }
            }

            props.onKeyPress(e);

            if (props.focusAfterAutocomplete) {
                inputFieldRef.current.focus();
            }

        } catch (e) { console.log(e) }

        if (!props.chipInput) {
            let val = processAutocompleteHighlight(e);
            if (val !== undefined) {
                if (props.setter) props.setter(val);
            }
        }

    }

    let keyCatcherKeys = ['Enter', ','];
    let expandingMaxWidth = controlMaxWidth - (isMobile ? 30 : 0);
    if (Dimensions.get('window').width <= 360) expandingMaxWidth = isMobile ? Dimensions.get('window').width * .8 - 30 : controlMaxWidth;
    let useKeyupAutocorrect = true;
    i = (
        <View>
            <TextInput
                testID={props.testID}
                accessibilityLabel={props.ariaLabel}
                accessibilityLabelledby={props.ariaLabelledBy}
                maxLength={props.maxLength}
                nativeID={props.id}
                style={[inputStyle,
                    (props.style ? props.style : null),
                    (props.prefixAdornment ? { paddingLeft: 37, paddingRight: 30 } : null),
                    (props.postfixAdornment ? { paddingRight: 37 } : null),
                    props.width ? { width: props.isExpanding ? expandingMaxWidth : props.width }
                        : { width: props.isExpanding ? expandingMaxWidth : controlMaxWidth },

                ]}
                onBlur={(e) => {
                    setIsFocused(false);
                    e.target.value = autocorrectField(e.target.value);
                    setInputVal(e.target.value);
                    props.onBlur ? props.onBlur(e) : null;
                    props.setter ? props.setter(e.target.value) : null;
                }}
                disabled={props.disabled}
                autoComplete={Platform.OS === 'web' ? 'off' : 'none'}
                onFocus={() => { setIsFocused(true); }}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}

                onKeyPress={(e) => {
                    if (keyCatcherKeys.includes(e.key)) {
                        // if the pressed key is one we need to catch, otherwise, we'll use keyup
                        keyCatcher(e);
                    }
                }}
                onKeyDown={(e) => { if (props.onKeyDown) return props.onKeyDown(e) }}
                onKeyUp={(e) => {
                    keyCatcher(e);
                    if (!autocompleteOpen) {
                        if (e.key == 'Escape')
                            if (props.onEscape) props.onEscape(e);
                    }
                    if (props.onKeyUp) return props.onKeyUp(e);
                }}
                onChangeText={(newVal) => {
                    let previous = inputVal;
                    let autocorrected = false;
                    newVal = newVal.trimStart();
                    if (props.correctTypos && (newVal.length > previous.length)) {
                        let checkNewVal = newVal.toUpperCase();
                        let bypass = true;
                        if (props.autocomplete) bypass = false;
                        if (bypass || props.autocomplete.find(e => e.includes(checkNewVal)) === undefined) {
                            let autocorrectedVersion = autocorrectField(newVal, previous, useKeyupAutocorrect);
                            if (!Data.zysys_smart_cmp(newVal, autocorrectedVersion)) {
                                newVal = autocorrectedVersion;
                                autocorrected = true;
                            }
                        }
                    }

                    if (props.onChangeText) {
                        if (props.onChangeText(newVal) === false) {
                            return;
                        }
                    }

                    if (props.chipInput) {
                        return processAutocompleteHighlight(e);
                    }


                    newVal = newVal.replace(/  /g, ' ');

                    let ignoreChars = /\(/g;


                    setInputVal(newVal);
                    let wordsInInput = newVal.toUpperCase().split(/[\s]+/).map(x => Data.zysys_clean_str(x)).filter(element => element);
                    let setWordsInInput = new Set(wordsInInput);

                    /* autocomplete logic */
                    if (props.autocomplete) {

                        let autocompleteFilter = async () => {

                            // assume everything is uppercase
                            let options = props.autocomplete.filter((str) => {
                                if (props.autocompleteFromStartOnly) {
                                    return str.toUpperCase().replace(ignoreChars, '').startsWith(newVal.toUpperCase());
                                } else {
                                    // transposed words

                                    // fuzzy variant
                                    if (wordsInInput.length < 2) {
                                        return str.toUpperCase().replace(ignoreChars, '').includes(newVal.toUpperCase().replace(ignoreChars, ''));
                                    }
                                    if ((wordsInInput.length - wordsInInput.filter(word => { return str.replace(ignoreChars, '').toUpperCase().replace(/\(/g, '').includes(word) }).length) <= Constants.fuzzyCondSearchTolerance) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            });

                            /* Autocomplete sorting rules
                                1. starts with at
                                2. only the same words in different orders
                                3. different number of words in increasing count order (no tolerance)
                                4. same number of words (with tolerance)
                                5. different number of words in increasing count order (with tolerance)
                            */

                            let ourList = { 'startsWith': [], 'sameWords': [], 'independentWordIntersection': [], 'wordCountNoTolerance': {}, 'sameNumWithTolerance': [], 'wordCountWithTolerance': {} };
                            // assume all options are uppercase
                            options.forEach(option => {
                                // Clean and split the option once
                                let cleanedOption = option.replace(ignoreChars, '');
                                let wordsInOption = cleanedOption.split(/[\s]+/).map(Data.zysys_clean_str).filter(Boolean);

                                // Create a Set for faster lookups
                                let setWordsInOption = new Set(wordsInOption);
                                let wordsInOptionIsSupersetOfInput = wordsInInput.every(word => setWordsInOption.has(word));

                                // Determine the category of the option
                                let isStartMatch = cleanedOption.toUpperCase().startsWith(newVal.replace(ignoreChars, '').toUpperCase());
                                let isSameLength = wordsInOption.length === wordsInInput.length;
                                let lengthDiff = Math.abs(wordsInInput.length - wordsInOption.length);

                                if (isStartMatch) {
                                    ourList.startsWith.push(option);
                                } else if (isSameLength) {
                                    if (Data.listsEqual(setWordsInInput, setWordsInOption, true)) {
                                        ourList.sameWords.push(option);
                                    } else {
                                        ourList.sameNumWithTolerance.push(option);
                                    }
                                } else {
                                    if (wordsInOptionIsSupersetOfInput) {
                                        if (!ourList.wordCountNoTolerance.hasOwnProperty(lengthDiff)) {
                                            ourList.wordCountNoTolerance[lengthDiff] = [];
                                        }
                                        ourList.wordCountNoTolerance[lengthDiff].push(option);
                                    } else {
                                        if (!ourList.wordCountWithTolerance.hasOwnProperty(lengthDiff)) {
                                            ourList.wordCountWithTolerance[lengthDiff] = [];
                                        }
                                        ourList.wordCountWithTolerance[lengthDiff].push(option);
                                    }
                                }

                                // Independent intersection check
                                if (wordsInInput.every(word => cleanedOption.includes(word))) {
                                    ourList.independentWordIntersection.push(option);
                                }
                            });

                            let wordCountKeysWithTolerance = Object.keys(ourList.wordCountWithTolerance);
                            wordCountKeysWithTolerance = wordCountKeysWithTolerance.sort();
                            let wordCountOptionsWithTolerance = [];
                            wordCountKeysWithTolerance.forEach(function (key) {
                                wordCountOptionsWithTolerance = wordCountOptionsWithTolerance.concat(ourList.wordCountWithTolerance[key]);
                            });

                            let wordCountKeysNoTolerance = Object.keys(ourList.wordCountNoTolerance);
                            wordCountKeysNoTolerance = wordCountKeysNoTolerance.sort();
                            let wordCountOptionsNoTolerance = [];
                            wordCountKeysNoTolerance.forEach(function (key) {
                                wordCountOptionsNoTolerance = wordCountOptionsNoTolerance.concat(ourList.wordCountNoTolerance[key]);
                            });

                            // Initialize groupedOptions directly
                            let groupedOptions = [
                                ourList.startsWith.sort((a, b) => a.split(/\s+/).length - b.split(/\s+/).length),
                                ourList.sameWords,
                                wordCountOptionsNoTolerance,
                                ourList.independentWordIntersection,
                                ourList.sameNumWithTolerance,
                                wordCountOptionsWithTolerance
                            ];

                            // Prepare frequency sorted map and scaling factors
                            const maxScaleFactor = groupedOptions.length;
                            const freqSorted = new Map();
                            const orig_groups = [...groupedOptions];
                            let foundSomething = false;

                            if (props.frequencyGraphs && props.frequencyGraphs.hasOwnProperty('cond_freq')) {
                                // Calculate frequency and scale factor
                                groupedOptions.flat().forEach(option => {
                                    if (!foundSomething && props.frequencyGraphs.cond_freq?.[option]) {
                                        foundSomething = true;
                                    }
                                    const frequency = (props.frequencyGraphs.cond_freq[option] || 0) + 1;
                                    const groupIndex = groupedOptions.findIndex(group => group.includes(option));
                                    const scaleFactor = Math.max(1, maxScaleFactor - groupIndex);
                                    freqSorted.set(option, frequency * scaleFactor);
                                });

                                if (!foundSomething) {
                                    // If we didn't find anything, reset the grouped options
                                    groupedOptions = orig_groups;
                                }

                                // Sort each group by frequency
                                groupedOptions.forEach(group => {
                                    group.sort((a, b) => freqSorted.get(b) - freqSorted.get(a));
                                });
                            }

                            // Concatenate all sorted groups
                            const sortedOptions = groupedOptions.flat();




                            setAutocompleteOptions(Array.from(new Set(sortedOptions)));


                            if (autocorrected && (ourList.startsWith.length == 0 || (ourList.startsWith.length == 1 && ourList.startsWith[0] == newVal))) {
                                setHideAutocomplete(true);
                                setAutocompleteOptions([]);
                            }

                        };
                        autocompleteFilter();
                    }

                    if (props.setter && !props.onlySyncOnBlur) props.setter(newVal);
                }}
                onChange={(e) => { try { props.onChange(e); } catch (e) { } }}
                placeholder={props.placeholder}
                type={props.type}
                defaultValue={props.defaultValue}
                value={inputVal}
                ref={props.refVal ? props.refVal : (field) => inputFieldRef.current = field}
                keyboardType={props.type == 'number' ? 'number-pad' : (props.keyboardType ? props.keyboardType : 'default')}
                placeholderTextColor={Constants.colors.captionColor}
                tabindex={props.tabindex}
                autoFocus={props.autoFocus}
                editable={props.hasOwnProperty('editable') ? props.editable : !props.readonly}
            />
            {autocompleteOptions.length > 0 ? <AutocompleteOptions hideAutocomplete={hideAutocomplete} /> : null}
            <ShowChips />
        </View>);

    if (props.getterSetterType == 'array') {
        /* useful for array fields like for Expanding Text Field */
        let expandingMaxWidth = (isMobile ? controlMaxWidth - 30 : controlMaxWidth);
        i = (
            <View>
                <TextInput
                    testID={props.testID}
                    maxLength={props.maxLength}
                    accessibilityLabel={props.ariaLabel}
                    accessibilityLabelledby={props.ariaLabelledBy}
                    nativeID={props.id}
                    style={[inputStyle,
                        (props.style ? props.style : null),
                        (props.prefixAdornment ? { paddingLeft: 37, paddingRight: 30 } : null),
                        (props.postfixAdornment ? { paddingRight: 37 } : null),
                        props.width ? { width: props.isExpanding ? expandingMaxWidth : props.width }
                            : { width: props.isExpanding ? expandingMaxWidth : controlMaxWidth },
                    ]}
                    onBlur={(e) => {
                        setIsFocused(false);
                        props.onBlur ? props.onBlur(e) : null
                        e.target.value = autocorrectField(e.target.value);
                        if (props.setter) props.setter(e.target.value);

                    }}
                    onFocus={() => { setIsFocused(true); }}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    onKeyPress={(e) => {
                        try {
                            props.onKeyPress(e);

                            if (props.focusAfterAutocomplete) {
                                inputFieldRef.current.focus();
                            }
                        } catch (e) { }

                    }}
                    onChangeText={(newVal) => {
                        let previous = inputVal;
                        try { props.onChangeText(newVal) } catch (e) { };

                        if (props.correctTypos) {
                            newVal = autocorrectField(newVal, previous, true);
                        }

                        if (typeof props.setter !== 'undefined') props.setter(newVal);


                        setInputVal(newVal);

                        /* autocomplete logic */
                        if (props.autocomplete) {

                            // assume everything is uppercase
                            let options = props.autocomplete.filter((str) => {
                                if (props.autocompleteFromStartOnly) {
                                    return str.startsWith(newVal.toUpperCase());
                                } else {
                                    return str.includes(newVal.toUpperCase())
                                }
                            });
                            setAutocompleteOptions(options);
                        }
                    }}
                    onChange={(e) => { try { props.onChange(e); } catch (e) { } }}
                    placeholder={props.placeholder}
                    type={props.type}
                    defaultValue={props.defaultValue}
                    value={props.value ? props.value : inputVal}
                    onKeyUp={(e) => { if (props.onKeyUp) return props.onKeyUp(e) }}
                    ref={props.refVal ? props.refVal : (field) => inputFieldRef.current = field}
                    keyboardType={props.type == 'number' ? 'number-pad' : (props.keyboardType ? props.keyboardType : 'default')}
                    placeholderTextColor={Constants.colors.captionColor}
                    autoFocus={props.autoFocus}
                    editable={props.hasOwnProperty('editable') ? props.editable : !props.readonly}
                />
                {autocompleteOptions.length > 0 ? <AutocompleteOptions /> : null}
                <ShowChips />
            </View>)
    }


    if (props.prefixAdornment) {
        let [inX, setInX] = useState(false);
        useEffect(() => {
            try {
                if (inputVal == '') setInX(false);
            } catch (e) { /* nothing */ }
        }, [inputVal]);
        return (<>
            <View style={[{ flexDirection: 'row', alignItems: props.alignBaseline ? 'baseline' : 'center', justifyContent: 'flex-start', alignContent: 'center' }, props.iconStyleWrap]}>
                <Icon as={AntDesign} name={props.prefixAdornment == 'search' ? 'search1' : props.prefixAdornment} color={Constants.colors.disabledInputBorder} style={[styles.moreInfoIcon, { marginRight: -20, marginLeft: 3 /* looks like this is the number from analyzing...maybe based on widths...*/, zIndex: 5, paddingLeft: 5 }, props.iconStyle]} />
                {i}
                {props.prefixAdornment == 'search' && inputVal != '' ?
                    <Pressable onHoverIn={() => { setInX(true) }} onHoverOut={() => { setInX(false) }} onPressOut={() => {
                        if (props.readonly) return;
                        props.setter('');
                        setInputVal('');
                        setForceCloseAutocomplete('');
                        setHideAutocomplete(true);
                        setIsFocused(true);
                        try {
                            if (props.refVal) {
                                props.refVal.current.focus();
                            } else {
                                inputFieldRef.current.focus();
                            }
                        } catch (e) { }
                        if (props.onErase) props.onErase();
                    }}
                        onMouseUp={() => {
                            if (props.readonly) return;
                            props.setter('');
                            setInputVal('');
                            setForceCloseAutocomplete('');
                            setHideAutocomplete(true);
                            setIsFocused(true);
                            try {
                                if (props.refVal) {
                                    props.refVal.current.focus();
                                } else {
                                    inputFieldRef.current.focus();
                                }
                            } catch (e) { }
                            if (props.onErase) props.onErase();
                        }}
                        style={{ marginLeft: -25, marginRight: 9 /* looks like this is the number from analyzing...maybe based on widths...*/, zIndex: 5, paddingRight: 0 }}>
                        <Icon as={AntDesign} name={'close'} color={Constants.colors.disabledInputBorder} style={[styles.moreInfoIcon, { zIndex: 5, paddingRight: 0 }, inX ? { display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 50, backgroundColor: Constants.colors.gray, paddingRight: 0, marginRight: -4.5, marginLeft: -4.5 /* half the margin right */, height: 25, width: 25 } : null, props.iconStyle]} />
                    </Pressable>
                    : null}
            </View ></>)
    }
    if (props.postfixAdornment) {
        return (<><View style={[{ flex: 1, flexDirection: 'row', alignItems: props.alignBaseline ? 'baseline' : 'center', justifyContent: 'center', alignContent: 'center' }, props.iconStyleWrap]}>
            {i}
            <Icon as={FontAwesome} name={props.postfixAdornment} color={Constants.colors.label} style={[styles.moreInfoIcon, { marginLeft: -25, marginRight: 9 /* looks like this is the number from analyzing...maybe based on widths...*/, zIndex: 5, paddingRight: 5 }, props.iconStyle]} />
        </View ></>)
    }

    return i;
}
const forwardedTextInputField = React.forwardRef(TextInputField);

export default forwardedTextInputField;

export function SelectField(props) {

    const [itemValue, setItemValue] = useState(defaultValue);
    const [isFocused, setIsFocused] = useState(false);

    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let mobileWidth = (Dimensions.get('window').width - 120);
    if (Dimensions.get('window').width <= 360) mobileWidth = '80vw'
    let controlMaxWidth = isMobile ? mobileWidth : Constants.controlMaxWidth;
    if (props.style && props.style.hasOwnProperty('width')) {
        controlMaxWidth = isMobile ? mobileWidth : props.style.width;
    }

    useEffect(() => {
        if (props.getter) {
            setItemValue(props.getter + '')
        }
    }, [props.getter])
    useEffect(() => {
        if (props.value) {
            setItemValue(props.value + '')
        }
    }, [props.value])
    useEffect(() => {
        if (props.defaultValue) {
            let defaultValue = props.defaultValue ? props.defaultValue : null;
            try {
                if ((typeof defaultValue) == 'object') {
                    defaultValue = props.defaultValue.value;
                }
            } catch (e) { }
            setItemValue(defaultValue)
        }
    }, []);


    const inputStyle = [{ color: Constants.colors.textColor }, { paddingLeft: 14.5, paddingRight: 14.5 }, props.style, { width: controlMaxWidth, maxWidth: controlMaxWidth }, props.isSortField ? { paddingLeft: 14.5 + 20, paddingRight: 14.5 - 20, } : null];

    let defaultValue = props.defaultValue ? props.defaultValue : null;
    try {
        if ((typeof defaultValue) == 'object') {
            defaultValue = props.defaultValue.value;
        }
    } catch (e) { }


    return (<View style={[props.wrapperStyle]}>
        <Select nativeID={props.name} id={props.name} name={props.name}
            style={[{ height: 40, width: props.maxWidth ? props.maxWidth : controlMaxWidth - 20 }, inputStyle]}
            minWidth={props.maxWidth ? props.maxWidth : controlMaxWidth}
            _hover={{ ...Constants.selectTheme, maxWidth: props.maxWidth ? props.maxWidth : controlMaxWidth, width: controlMaxWidth }}
            _focus={{ ...Constants.selectTheme, maxWidth: props.maxWidth ? props.maxWidth : controlMaxWidth, width: controlMaxWidth }}
            _active={{ ...Constants.selectTheme, maxWidth: props.maxWidth ? props.maxWidth : controlMaxWidth, width: controlMaxWidth }}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            selectedValue={itemValue}
            accessibilityLabel={props.accessibilityLabel} placeholder={props.placeholder}
            _selectedItem={{
                endIcon: ""
            }} dropdownIcon={<ChevronDownIcon size="3" mr={2} style={{ marginLeft: -20 }} color='#aaa' />}
            onValueChange={(itemValue) => { props.setter ? props.setter(itemValue) : setItemValue(itemValue); if (props.onValueChange) props.onValueChange(itemValue); }}
            isDisabled={props.isDisabled || props.readonly || (props.hasOwnProperty('editable') ? !props.editable : null)}
            isFocusVisible={false}
            variant='unstyled'
            testID={props.testID}
        >

            {props.options.map((x) => { return <Select.Item key={x.value} label={x.label} value={x.value} id={props.id + '-' + x.value} /> })}

        </Select >
    </View>);
}

export function FilterField(props) {

    const [itemValue, setItemValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [isOpen, setIsOpen] = useState(props.setOpen ? props.open : false);

    const size = props.size ? props.size : 'normal';

    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let mobileWidth = (Dimensions.get('window').width - 120);
    if (Dimensions.get('window').width <= 360) mobileWidth = '80vw'
    let controlMaxWidth = isMobile ? mobileWidth : Constants.controlMaxWidth;
    if (props.style && props.style.hasOwnProperty('width')) {
        controlMaxWidth = isMobile ? mobileWidth : props.style.width;
    }

    useEffect(() => {
        if (props.getter) {
            setItemValue(props.getter + '')
        }
    }, [props.getter])
    useEffect(() => {
        if (props.value) {
            setItemValue(props.value + '')
        }
    }, [props.value]);

    if (props.setForceClose) {
        useEffect(() => {
            if (props.forceClose == true) {
                setIsOpen(false);
                props.setForceClose(false);
                if (props.setOpen) {
                    props.setOpen(false);
                }
            }
        }, [props.forceClose])
    }


    let loadCount = useRef(0);


    const inputStyle = [{ color: Constants.colors.textColor }, props.style, { paddingLeft: 14.5, paddingRight: 14.5, }];

    let displayText = props.blankText;

    if (!props.options) return;
    return (<>
        {isOpen ? <Pressable style={{ position: 'fixed', width: '100vw', backgroundColor: 'transparent', top: 0, left: 0, bottom: 0, right: 0 }} onPress={() => { props.setForceClose(true); }}></Pressable> : null}
        <Pressable onMouseEnter={() => setIsHover(true)} onFocus={() => setIsFocused(true)} onBlur={() => { setIsFocused(false) }} onMouseOut={() => setIsHover(false)}
            onPress={() => { let newVal = !isOpen; setIsOpen(newVal); props.setOpen(newVal); }}
            onKeyDown={(e) => {
                if (e.code == 'Space') {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                }
                if (e.code == 'ArrowDown') {
                    e.preventDefault();
                    setIsOpen(true);
                }
                if (e.code == 'ArrowUp') {
                    e.preventDefault();
                    setIsOpen(false);
                }
                if (e.code == 'ArrowLeft' || e.code == 'ArrowRight') {
                    e.preventDefault();
                    setIsOpen(false);
                }
            }}
            style={[Constants.styles.textControl, {
                flex: 1,
                backgroundColor: size == 'xs' ? 'transparent' : '#FFF',
                borderWidth: size == 'xs' ? 0 : 1,
                borderColor: Constants.colors.disabledButton,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: Constants.inputHeight,
                width: props.width, minWidth: props.width, maxWidth: props.width,
            }, size != 'xs' && (isHover || isFocused) ? { ...Constants.selectTheme, width: props.width, minWidth: props.width, maxWidth: props.width, } : null, props.style]}>

            <Text testID={props.testID} selectable={false} style={[{ paddingLeft: 14.5, paddingRight: 14.5, paddingTop: 10, paddingBottom: 10, color: Constants.colors.textColor }]}>
                <Icon as={Feather} name='filter' style={{ marginTop: 2, paddingRight: 4 }} />
                {size == 'xs' && displayText.length > 0 ?
                    <View style={{
                        backgroundColor: Constants.colors.headerColor, borderRadius: '50%',

                        paddingTop: displayText.length == 2 ? 3 : 2,
                        paddingBottom: displayText.length == 2 ? 3 : 2,
                        paddingLeft: displayText.length == 1 ? 5 : 4,
                        paddingRight: displayText.length == 1 ? 5 : 4,
                        fontSize: '.5rem',
                        fontWeight: 'bold',
                        position: 'absolute',
                        marginTop: -7,
                        marginLeft: -12,
                        border: '2px solid white',
                    }}>
                        <Text style={{ color: Constants.colors.primaryColorText }}>{displayText}</Text>
                    </View> : displayText}</Text>

            {size == 'xs' ? null : <Icon as={Feather} name={isOpen ? 'chevron-up' : 'chevron-down'} style={{ marginRight: 20 }} />}
        </Pressable >
        <View style={[styles.filterDropdown, { width: props.width, maxWidth: props.width, }, !isOpen ? { display: 'none' } : null, props.dropdownStyle]}>
            {props.options.map((obj, idx) => {
                if (obj.value == '') {
                    // Heading
                    return <Text style={{ marginTop: idx == 0 ? 0 : 15, marginBottom: 5 }}>{obj.label}</Text>
                } else {
                    // Option
                    return <CheckField key={`filter-${obj.value}-${loadCount.current}`} label={obj.label} checked={props.getter.includes(obj.value)}
                        onChange={((e) => {
                            let localGetter = props.getter
                            if (props.getter.includes(obj.value)) {
                                localGetter = props.getter.filter((x) => x != obj.value);
                                props.setter(localGetter);
                            } else {
                                localGetter = localGetter.concat(obj.value);
                                props.setter(localGetter);
                            }

                            if (props.onChange) props.onChange(e);
                        })}></CheckField>
                }



            })}

            <Pressable style={[{ marginTop: 43, alignItems: 'center', justifyContent: 'center', }]}
                onPress={(e) => {
                    props.setter([]);
                    loadCount.current++;
                    if (props.onChange) props.onChange(e);
                    if (props.focuser) props.focuser.current.focus();
                }}
                onKeyDown={(e) => {
                    if (e.code == 'Space') {
                        props.setter([]);
                        loadCount.current++;
                        if (props.onChange) props.onChange(e);
                        e.preventDefault();
                        if (props.focuser) props.focuser.current.focus();
                    }
                }}
            >
                <Text style={[Constants.styles.link]}>Clear Selections</Text>
            </Pressable>


        </View>
    </>);
}

export const CheckField = React.memo((props) => {
    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let mobileWidth = (Dimensions.get('window').width - 120);
    if (Dimensions.get('window').width <= 360) mobileWidth = '80vw'
    let controlMaxWidth = isMobile ? mobileWidth : Constants.controlMaxWidth;
    if (props.style && props.style.hasOwnProperty('width')) {
        controlMaxWidth = isMobile ? mobileWidth : props.style.width;
    }
    const [value, setValue] = React.useState("");
    const [isChecked, setChecked] = React.useState(props.hasOwnProperty('checked') ? props.checked : false);
    useEffect(() => {
        if (props.value) {
            setChecked(props.value);
        }
        if (props.getter) {
            setChecked(props.getter);
        }
    }, [props.getter]);

    useEffect(() => {
        if (props.setter) {
            props.setter(isChecked);
        }
    }, [isChecked]);


    const checkedStyle = {
        borderWidth: 1.2,
        borderColor: Constants.colors.primaryButton,
        backgroundColor: Constants.colors.primaryButton,
        height: 18,
        width: 18,
        cursor: 'pointer',
    };

    const uncheckedStyle = {
        borderWidth: 1.2,
        borderColor: Constants.colors.disabledButton,
        height: 18,
        width: 18,
        cursor: 'pointer',
    };
    let optionIdx = -1;
    let [lbl, setLbl] = useState(props.label);
    if (lbl) {
        if (props.titleCase) {
            hyphenate(props.label).then(result => setLbl(result.toLowerCase()));
        } else {
            hyphenate(props.label).then(result => setLbl(result));
        }
    }
    return <>
        <View style={[Constants.styles.flexRow, { marginTop: 8, marginBottom: 8, alignItems: 'center' }, props.style]}>
            <Pressable
                onKeyDown={(e) => {
                    if (props.readonly) return e.preventDefault();
                    if (e.code == 'Space') {
                        setChecked(!isChecked);
                        if (props.onChange) props.onChange(e);
                        e.preventDefault();
                    }
                }}
                onPressOut={(e) => {
                    if (props.readonly) return e.preventDefault();
                    setChecked(!isChecked);
                    if (props.onChange) props.onChange(e);
                }} style={isChecked ? checkedStyle : uncheckedStyle}>
                {isChecked ?
                    <Icon as={AntDesign} name="check" color={Constants.colors.paneColor} style={{ marginLeft: 0, marginTop: 0, marginBottom: 0 }} />
                    : null}
            </Pressable>
            {lbl ?
                <Pressable style={[{ marginLeft: 9, flex: 1, flexDirection: 'row', alignItems: 'center' }, props.labelWrapperStyle]}
                    onKeyDown={(e) => {
                        if (props.readonly) return e.preventDefault();
                        if (e.code == 'Space') {
                            setChecked(!isChecked);
                            if (props.onChange) props.onChange(e);
                            e.preventDefault();
                        }
                    }}

                    onPressOut={(e) => {
                        if (props.readonly) return e.preventDefault();
                        setChecked(!isChecked);
                        if (props.onChange) props.onChange(e);
                    }}><Text selectable={false} style={[Constants.styles.checkboxLabel, props.titleCase ? { textTransform: 'capitalize' } : null, props.labelStyle]}>{lbl}</Text></Pressable>
                : null}
        </View>
    </>;




});


export function RadioField(props) {
    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let mobileWidth = (Dimensions.get('window').width - 120);
    if (Dimensions.get('window').width <= 360) mobileWidth = '80vw'
    let controlMaxWidth = isMobile ? mobileWidth : Constants.controlMaxWidth;
    if (props.style && props.style.hasOwnProperty('width')) {
        controlMaxWidth = isMobile ? mobileWidth : props.style.width;
    }
    const [value, setValue] = React.useState("");
    useEffect(() => {
        if (props.value) {
            setValue(props.value);
        }
        if (props.getter) {
            setValue(props.getter);
        }
    }, [props.getter]);

    useEffect(() => {
        if (props.setter) {
            props.setter(value);
        }
    }, [value]);


    if (props.options === undefined) {
        return;
    }
    let optionIdx = -1;

    return FastRadio(props);

    return <><Radio.Group tabStop={1} value={props.getter ? props.getter : ""}
        style={[props.horizontal ? { flex: 1, flexDirection: 'row', justifyContent: 'flex-start', width: controlMaxWidth } : null,
        props.gapped ? {} : null, props.style]} name={props.name} id={props.name} isDisabled={props.readonly}
        nativeID={props.name} accessibilityLabel={props.name} onChange={(nextValue) => {
            if (props.readonly) return;
            setValue(nextValue);
            if (props.setter) {
                props.setter(nextValue);
            }
            props.onChange ? props.onChange(nextValue) : null
        }}>
        <View style={[props.horizontal ? [Constants.styles.flexRow, { justifyContent: 'flex-start' }] : Constants.styles.flex, props.optionStyle, props.optionWrapperStyle]}>
            {props.options.map((x) => {
                optionIdx++;
                let pad = { marginTop: 10, marginBottom: 10 };
                let optStyle = [
                    x.style ? x.style : null,
                    props.optionStyle
                ];
                return <Radio
                    isDisabled={props.readonly}
                    key={x.id} style={optStyle} labelStyle={props.labelStyle}
                    nativeId={x.id} id={x.id} accessibilityLabel={x.value} name={props.name} value={x.value} my={1}>
                    <Text style={[props.addPadding ? pad : null, props.horizontal ? { marginRight: 30, justifyContent: 'flex-start' } : null, value == x.value ? props.activeLabelStyle : null, { color: Constants.colors.textColor, }, props.labelStyle]}>{x.label}</Text>
                </Radio>
            })}
        </View>
    </Radio.Group></>;


    return (
        <Form.Check tabstop={1} id={props.id} type="radio" label={props.label} name={props.name} style={props.style} accessibilityLabel={props.label}></Form.Check>
    );
}

export function FastRadio(props) {
    let diameter = 18;
    let innerDiameter = 8;
    let mobileWidth = (Dimensions.get('window').width - 120);
    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let controlMaxWidth = isMobile ? mobileWidth : Constants.controlMaxWidth;
    if (props.style && props.style.hasOwnProperty('width')) {
        controlMaxWidth = isMobile ? mobileWidth : props.style.width;
    }
    return (
        <View style={[{ gap: (props.optionGap ? props.optionGap : 7), ...Constants.styles.flex }, (props.horizontal) ? { gap: 5, flex: 1, flexDirection: 'row', justifyContent: 'flex-start', width: controlMaxWidth } : null,
        props.gapped ? {} : null, props.style, props.radioStyle]}>
            {props.options.map((x) => {
                return (
                    <View nativeID={x.id}>
                        <Pressable
                            onPress={() => { props.setter(x.value ? x.value : x.label); if (props.onChange) props.onChange(x.value ? x.value : x.label) }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <View
                                style={[{ color: Constants.colors.textColor, borderRadius: 20, height: diameter, width: diameter, marginRight: 10, borderWidth: 1, borderColor: Constants.colors.disabledButton },
                                props.getter == (x.value ? x.value : x.label) ? { color: Constants.colors.textColor, backgroundColor: Constants.colors.paneColor, borderWidth: 1, borderColor: Constants.colors.primaryColorBG, alignItems: 'center', justifyContent: 'center', display: 'flex' }
                                    : null]}>
                                {props.getter == (x.value ? x.value : x.label) ? <View style={{ margin: 5, border: Constants.colors.primaryColorBG, borderRadius: innerDiameter, backgroundColor: Constants.colors.primaryColorBG, height: innerDiameter, width: innerDiameter }}></View> : null}
                            </View>
                            <Text style={[{ color: Constants.colors.textColor }, props.getter == (x.value ? x.value : x.label) ? { fontWeight: 500, ...props.activeLabelStyle } : null, props.labelStyle, props.optionStyle]}>{x.label}</Text>
                        </Pressable>
                    </View>
                )
            })}
        </View>
    )
}

export function SwitchButton(props) {
    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let mobileWidth = (Dimensions.get('window').width - 120);
    if (Dimensions.get('window').width <= 360) mobileWidth = '80vw'
    let controlMaxWidth = isMobile ? mobileWidth : Constants.controlMaxWidth;
    if (props.style && props.style.hasOwnProperty('width')) {
        controlMaxWidth = isMobile ? mobileWidth : props.style.width;
    }
    return (
        <View style={props.styles.selectorButtonsWrap}>
            <Pressable disabled={props.readonly} style={[props.styles.selectorButton, props.styles.leftButton, props.getMode ? props.styles.activeMode : props.styles.inactiveMode]} onPress={() => { props.setMode(true); }}>
                <Text selectable={false} style={props.getMode ? props.styles.activeModeText : props.styles.inactiveModeText}>Face Value</Text>
            </Pressable>
            <Pressable disabled={props.readonly} style={[props.styles.selectorButton, props.styles.rightButton, props.getMode ? props.styles.inactiveMode : props.styles.activeMode]} onPress={() => { props.setMode(false); }}>
                <Text selectable={false} style={props.getMode ? props.styles.inactiveModeText : props.styles.activeModeText}>Max Monthly Budget</Text>
            </Pressable>
        </View>
    );
}

export function BooleanSwitch(props) {
    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let mobileWidth = (Dimensions.get('window').width - 120);
    if (Dimensions.get('window').width <= 360) mobileWidth = '80vw'
    let controlMaxWidth = isMobile ? mobileWidth : Constants.controlMaxWidth;
    if (props.style && props.style.hasOwnProperty('width')) {
        controlMaxWidth = isMobile ? mobileWidth : props.style.width;
    }
    const action = () => {
        if (props.readonly) return;
        let newVal = !props.get
        props.set(newVal);
        try {
            props.setStates.forEach(function (set) {
                set.item(set.value);
            });
        } catch (e) { }

        if (props.savePreference) {
            Data.savePreference(props.savePreference, newVal);
        }
    };
    return (
        <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter]}>
            <Pressable testID={props.testID} onKeyUp={(e) => {
                if (props.readonly) return e.preventDefault();
                if (e.code == 'Space') {
                    action();
                    e.preventDefault();
                }
            }}
                onPress={() => {
                    if (props.readonly) return;
                    action();
                    if (props.onChange) props.onChange();
                }} style={[
                    {
                        maxWidth: 42,
                        minWidth: 42,
                        justifyContent: 'center',
                        height: 20,
                        borderRadius: 50,
                        backgroundColor: props.get ? Constants.colors.primaryButton : Constants.colors.secondaryButton.match(/^#F{3,6}/i) ? Constants.colors.captionColor : Constants.colors.captionColor,
                        alignContent: 'center',
                        borderWidth: 1,
                        borderColor: 'transparent',


                    },
                    props.get ? { flex: 1, flexDirection: 'row', borderWidth: 0 } : null
                ]
                }>
                {props.get ? <Icon as={AntDesign} name="check" color={Constants.colors.paneColor} style={{ marginLeft: 7, marginTop: 2, marginBottom: -2 }} /> : null}
                <View style={[{ backgroundColor: '#FFF', width: 16, height: 16, borderRadius: 50, marginLeft: 1 }, props.get ? { height: 16, width: 16, margin: 2, } : null]}></View>
            </Pressable>
            {props.label ? <>
                <Pressable disabled={props.readonly} onPress={() => {
                    action();
                    if (props.onChange) props.onChange();
                }} >

                    <Text selectable={false} style={[Constants.styles.checkboxLabel, props.titleCase ? { textTransform: 'capitalize' } : null, { marginLeft: 9 }, props.labelStyle]}>{props.label}</Text>

                </Pressable>
            </>
                : null}
        </View >
    )
}

const styles = new StyleSheet.create({
    filterDropdown: {
        position: 'absolute',
        backgroundColor: Constants.colors.paneColor,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 11,
        paddingRight: 11,
        borderRadius: Constants.borderRadius,
        borderWidth: 1,
        borderColor: Constants.colors.disabledButton,
        top: 42,
        left: 292, // padding + extra
        zIndex: 5,
    },
    header: {
        fontWeight: '700',
        paddingBottom: 15,
        fontSize: Constants.fontSizes.headerText,
        lineHeight: 18,
        paddingTop: 23,
        paddingLeft: Constants.leftOffset,
        borderBottomWidth: 1,
        borderColor: Constants.colors.gray,
        marginBottom: 55,
        boxSizing: 'border-box',
    },
    heading: {
        fontWeight: '700',
        fontSize: Constants.fontSizes,
    },
    card: {
        marginBottom: 93,
        justifyContent: 'space-between',
        flexDirection: 'column',
        color: Constants.colors.label,
        backgroundColor: Constants.colors.paneColor,
        zIndex: -1,
    },
    cardBody: {
        paddingLeft: Constants.leftOffset,
    },
    secondaryOption: {
        alignContent: 'right',
        paddingRight: Constants.leftOffset,
        fontSize: Constants.fontSizes.input,
        fontWeight: '600',
    },
    hidden: {
        display: 'none',
    },
    flex: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    autocompleteWrap: {
        position: 'absolute',
        marginTop: 40,
        zIndex: 1000,
        backgroundColor: Constants.colors.paneColor,
        width: '100%',
        overflow: 'visible',
        marginBottom: 200,
    },
    autocompleteOption: {
        zIndex: 999,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        paddingBottom: 10,
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: Constants.colors.gray,
        borderCollapse: 1,
        color: Constants.colors.textColor,
    },
    chip: {
        textTransform: 'uppercase',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 6,
        fontSize: Constants.fontSizes.badge,
        fontWeight: '500',
        marginRight: 4,
        minWidth: 'fit-content',
        marginTop: 4,
    },
    highlightAutocompleteOption: {
        backgroundColor: Constants.colors.autocompleteFocused,
    },
    unhighlightAutocompleteOptionText: {
        opacity: 0.8,
    }
});
