// src/AddItemWizard.js

import React, { useEffect, useState, useRef } from 'react'
import {
    View,
    StyleSheet,
    Pressable,
    Button,
    Platform,
    ActivityIndicator,
    useWindowDimensions,
    Dimensions
} from 'react-native'
import Pane from './Pane';
import Row from './Row';
import * as Constants from '../theme'
import TextInputField, { SelectField, RadioField, SwitchButton, BooleanSwitch, CheckField, FastRadio } from './Input';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Box, IconButton } from 'native-base';
import * as Data from './libs/data';

import { Select, CheckIcon, ChevronDownIcon, Radio, Icon } from "native-base";
import ToggleField from './ToggleField';
import * as DataTypes from './libs/DataTypes';
import KeyboardShortcut from './KeyboardShortcut';
import Text from './Text';

const AddMedicationDates = (props) => {
    let internalPane = props.internalPane;
    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;

    let lf_placeholder = "(i.e. 2 years ago)";
    let ff_placeholder = "(i.e. 2 years ago)";
    if (props.usePlaceholders) {

        if (props.lastFillPlaceholder != '')
            lf_placeholder = props.lastFillPlaceholder;
        if (props.firstFillPlaceholder != '')
            ff_placeholder = props.firstFillPlaceholder;
    }

    return (<>

        <Row label='Treatment is Ongoing?' internalPane={internalPane}>
            <View>
                <RadioField horizontal={props.horizontalOngoing ? true : false}
                    gapped={props.gapped ? props.gapped : false}
                    getter={props.isOngoing} setter={props.setIsOngoing}
                    name="ongoingTreatment" id="ongoingTreatment"
                    onChange={(val) => {
                        props.setIsOngoing(val)
                    }}
                    readonly={props.readonly}
                    labelStyle={{ marginRight: Constants.leftOffset }}
                    style={{ marginTop: 12, marginBottom: -12 }}
                    options={[
                        { label: 'Yes', value: 'Yes', id: 'ongoingYes', },
                        { label: 'No', value: 'No', id: 'ongoingNo', }
                    ]}></RadioField>
            </View>
        </Row>
        {
            (props.isOngoing == 'No') ?
                <Row label="Most Recent Fill" internalPane={internalPane}>
                    <ToggleField
                        internalPane={internalPane}
                        one={<TextInputField
                            value={props.lastFill} setter={props.setLastFill}
                            placeholder={lf_placeholder} style={[Constants.styles.textControl, isMobile ? {
                                maxWidth: '60vw',
                                width: '60vw',
                                minWidth: '60vw'
                            } : null]}></TextInputField>}
                        switchToOne='Use general date'
                        two={<TextInputField
                            value={props.lastFill} setter={props.setLastFill}
                            style={[Constants.styles.textControl, isMobile ? {
                                maxWidth: '60vw',
                                width: '60vw',
                                minWidth: '60vw'
                            } : null]} postfixAdornment='calendar' type="date"
                            placeholder='MM/DD/YYYY'></TextInputField>}
                        switchToTwo='Use specific date'
                        readonly={props.readonly}
                    >
                    </ToggleField>
                </Row> : null
        }

        <Row label="First Fill" internalPane={internalPane}>
            <ToggleField internalPane={internalPane}
                one={<TextInputField value={props.firstFill} setter={props.setFirstFill}
                    placeholder={ff_placeholder}
                    style={[Constants.styles.textControl, isMobile ? {
                        maxWidth: '60vw',
                        width: '60vw',
                        minWidth: '60vw'
                    } : null]}></TextInputField>}
                switchToOne='Use general date'
                two={<TextInputField value={props.firstFill} setter={props.setFirstFill}
                    style={[Constants.styles.textControl, isMobile ? {
                        maxWidth: '60vw',
                        width: '60vw',
                        minWidth: '60vw'
                    } : null]} postfixAdornment='calendar' type="date"
                    placeholder='MM/DD/YYYY'></TextInputField>}
                switchToTwo='Use specific date'
                readonly={props.readonly}
            >
            </ToggleField>
        </Row>
        <Row label="Daily Dosage" showOptional={1} internalPane={internalPane}>
            <TextInputField value={props.dosage} setter={props.setDosage}
                style={[Constants.styles.textControl, isMobile ? {
                    maxWidth: '60vw',
                    width: '60vw',
                    minWidth: '60vw'
                } : null]} readonly={props.readonly}></TextInputField>
            <Text selectable={false} style={{
                color: Constants.colors.captionColor,
                fontSize: Constants.fontSizes.caption,
                fontWeight: '500',
                marginTop: 9
            }}>Anything besides numbers will be ignored</Text>
        </Row>
    </>);
}

const AddConditionDates = (props) => {
    let internalPane = props.internalPane;
    return (<>
        <Row label='Current, Lifelong, or Permanent Condition?' internalPane={internalPane}>
            <View>
                <RadioField horizontal={props.horizontalOngoing ? true : false}
                    gapped={props.gapped ? props.gapped : false}
                    getter={props.isOngoing} setter={props.setIsOngoing}
                    readonly={props.readonly}
                    name="ongoingCondTreatment" id="ongoingCondTreatment"
                    options={[{ label: 'Yes', value: 'Yes', id: 'ongoingYes' },
                    { label: 'No', value: 'No', id: 'ongoingNo' }]}></RadioField>
            </View>
        </Row>
        {
            (props.isOngoing == 'No') ?
                <Row label="Last Treatment / Change" internalPane={internalPane} labelIcon={
                    <IconButton icon={<Icon as={FontAwesome} name="question-circle-o" color={Constants.colors.label}
                        style={[styles.moreInfoIcon, { marginLeft: 0 }]} />} />
                }
                    labelIconTooltip="For non-health conditions (like DUI), when was the last time status changed? This may be the date of the final court hearing or something like that. For health conditions, treatment is defined as taken maintenance med, changed medication, went to the doctor, had surgery, talked to the doctor specifically about, ...anything that happens DUE TO the condition.">
                    <ToggleField
                        internalPane={internalPane}
                        one={<TextInputField
                            value={props.lastTreatment} setter={props.setLastTreatment}
                            placeholder="(i.e. 2 years ago)" style={Constants.styles.textControl}></TextInputField>}
                        switchToOne='Use general date'
                        two={<TextInputField
                            value={props.lastTreatment} setter={props.setLastTreatment}
                            style={Constants.styles.textControl} postfixAdornment='calendar' type="date"
                            placeholder='MM/DD/YYYY'></TextInputField>}
                        switchToTwo='Use specific date'
                        readonly={props.readonly}
                    >
                    </ToggleField>
                </Row> : null
        }

        <Row label="Date Diagnosed" showOptional={1} internalPane={internalPane} labelIcon={
            <IconButton icon={<Icon as={FontAwesome} name="question-circle-o" color={Constants.colors.label}
                style={[styles.moreInfoIcon, { marginLeft: 0 }]} />} />
        }
            labelIconTooltip="The most recent diagnosis. For events like heart attacks, use the date of the last one.  Leave this blank if it doesn't make sense (like with family history) or for BPP to infer the diagnosis date from the treatment date (yielding a conservative result).">
            <ToggleField internalPane={internalPane}
                one={<TextInputField value={props.wasDiagnosed} getter={props.wasDiagnosed}
                    setter={props.setWasDiagnosed} placeholder="(i.e. 2 years ago)"
                    style={Constants.styles.textControl}></TextInputField>}
                switchToOne='Use general date'
                two={<TextInputField value={props.wasDiagnosed} getter={props.wasDiagnosed}
                    setter={props.setWasDiagnosed} style={Constants.styles.textControl}
                    postfixAdornment='calendar' type="date"
                    placeholder='MM/DD/YYYY'></TextInputField>}
                switchToTwo='Use specific date'
            >
                readonly={props.readonly}
            </ToggleField>
        </Row>
    </>);
}

const createMedicationObjectFromParams = (params) => {
    let x = new DataTypes.Medication();
    x.name(params.name);
    x.use(params.use);
    x.last_fill(params.last_fill);
    x.first_fill(params.first_fill);
    x.dosage(params.dosage);
    return x;
}

const createConditionObjectFromParams = (params) => {
    let x = new DataTypes.Condition();
    x.name(params.name);
    x.last_treatment(params.last_treatment);
    x.was_diagnosed(params.was_diagnosed);
    if (x.last_treatment() == '') {
        x.last_treatment(Data.get_formatted_date(new Date().toDateInputValue()));
    }
    if (x.was_diagnosed() == '') {
        if (x.last_treatment() == 'ONGOING') {
            x.was_diagnosed(Data.get_formatted_date(new Date().toDateInputValue()));
        } else {
            x.was_diagnosed(x.last_treatment());
        }
    }
    x.hasVisibleAsterisk = params.visibleAsterisk;
    x.hasHiddenAsterisk = params.hiddenAsterisk;
    return x;
}


const createConditionObjectFromMedObject = (med) => {
    let x = new DataTypes.Condition();
    x.name(med.use());
    x.last_treatment(med.last_fill());
    x.was_diagnosed(med.first_fill());
    if (x.last_treatment() == '') {
        x.last_treatment(Data.get_formatted_date(new Date().toDateInputValue()));
    }
    if (x.was_diagnosed() == '') {
        x.was_diagnosed(Data.get_formatted_date(new Date().toDateInputValue()));
    }
    x.hasVisibleAsterisk = true; /* we should deal with this later */
    x.hasHiddenAsterisk = true;
    return x;
}


const MedUses = ((props) => {
    if (props.knownUses.length == 0) return;
    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;


    let [selected, setSelected] = useState(props.use);
    let [sortOptionsByRecommended, setSortOptionsByRecommended] = useState(true);

    let [forceRedraw, setForceRedraw] = useState(0);

    let [options, setOptions] = useState([].concat(props.knownUsesOptions))

    useEffect(() => {
        setOptions([].concat(props.knownUsesOptions));
        if (sortOptionsByRecommended === true) {
            // sort by getFrequencyGraph known uses (assume 0 if unknown)
            if (props.getFrequencyGraphs.med_map.hasOwnProperty(props.searchName)) {
                setOptions(options.sort((a, b) => {
                    let aCount = props.getFrequencyGraphs.med_map[props.searchName][a.value] || 0;
                    let bCount = props.getFrequencyGraphs.med_map[props.searchName][b.value] || 0;
                    return bCount - aCount;
                }));
            }
        }
        setForceRedraw(forceRedraw + 1);

    }, [sortOptionsByRecommended, props.searchName, props.knownUses, props.knownUsesOptions]);


    let [isTogglePaneOpen, setIsTogglePaneOpen] = useState(true);
    let keyboardShortcutType = useRef('keypress');

    return (
        <>
            <View style={[Constants.styles.flexRow, { justifyContent: 'right', alignItems: 'center' }]}>
                <View style={[{
                    maxWidth: isMobile ? 214 : 214,
                    minWidth: isMobile ? 214 : 214,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 8,
                }]}>
                    <svg fill="#aaa" height="15px" width="15px" version="1.1" id="filterIco" style={{
                        position: 'absolute',
                        marginLeft: 10,
                        zIndex: 5,
                        marginRight: -15,
                        height: 15,
                        width: 15,
                        minHeight: 15,
                        minWidth: 15
                    }} viewBox="0 0 490 490">
                        <g>
                            <polygon points="85.877,154.014 85.877,428.309 131.706,428.309 131.706,154.014 180.497,221.213 217.584,194.27 108.792,44.46
		0,194.27 37.087,221.213"/>
                            <polygon points="404.13,335.988 404.13,61.691 358.301,61.691 358.301,335.99 309.503,268.787 272.416,295.73 381.216,445.54
		490,295.715 452.913,268.802"/>
                        </g>
                    </svg>

                    <SelectField id={`sort-search`}
                        defaultValue="true"
                        value={sortOptionsByRecommended ? 'true' : 'false'}
                        options={[{ label: 'Most Common', value: 'true', id: 'recommended' },
                        { label: 'A-Z', value: 'false', id: 'alphabetize' }]}
                        onValueChange={(val) => {
                            if (val == 'false') {
                                setSortOptionsByRecommended(false);
                            } else {
                                setSortOptionsByRecommended(true);
                            }
                        }}
                        style={{ background: 'transparent' }}
                        labelStyle={{ paddingLeft: 5 }}
                        isSortField={1}
                        maxWidth={214}
                        getter={sortOptionsByRecommended}
                        setter={setSortOptionsByRecommended}
                    ></SelectField>

                </View>
            </View>
            <Pressable onPress={() => props.setShowMedUses(false)} style={{
                zIndex: 9999,
                backgroundColor: Constants.colors.paneColor,
                position: 'absolute',
                right: 20,
                marginTop: 60
            }}>
                <svg width="24" height="24" style={{ marginRight: 0 }} viewBox="0 0 25 25" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                        fill={Constants.colors.captionColor} />
                </svg>
            </Pressable>
            <View
                style={[isTogglePaneOpen ? styles.infoBoxOpen : styles.infoBoxClosed, isTogglePaneOpen && isMobile ? styles.infoBoxOpenMobile : null, isTogglePaneOpen && props.knownUsesOptions ? styles.infoBoxOpenSortable : styles.infoBoxClosed, { marginTop: '2px' }]}>
                {isTogglePaneOpen ?
                    <View style={Constants.styles.flex} key={forceRedraw}>
                        <FastRadio addPadding={1} key={forceRedraw} style={{ paddingRight: 30 }}
                            labelStyle={{ fontWeight: '500', paddingRight: 30 }}
                            getter={selected} setter={setSelected}
                            options={options}
                            onChange={(val) => {
                                props.setUse(val);
                            }}
                        ></FastRadio>
                        <Text>[Other uses may be ignored by this version of BestPlanPro.]</Text>
                    </View>
                    : null}
                <KeyboardShortcut shortcut="!" type={keyboardShortcutType.current} callback={(e) => {
                    if (props.showMedUses) {
                        keyboardShortcutType.current = 'keyup';
                        props.setShowMedUses(!props.showMedUses);
                        e.preventDefault();
                    }
                }} />
            </View>
        </>
    )
});

const MedsForCond = React.memo((props) => {
    if (props.medsForCond.length == 0) return;
    if (props.searchName == "") return;

    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;


    let [selected, setSelected] = useState(props.use);


    let [sortOptionsByRecommended, setSortOptionsByRecommended] = useState(true);

    let [forceRedraw, setForceRedraw] = useState(0);

    let [options, setOptions] = useState([].concat(props.medsForCondOptions))

    useEffect(() => {
        setOptions([].concat(props.medsForCondOptions));
        if (sortOptionsByRecommended === true) {
            // sort by getFrequencyGraph known uses (assume 0 if unknown)
            if (props.getFrequencyGraphs.use_map.hasOwnProperty(props.searchName)) {
                setOptions(options.sort((a, b) => {
                    let aCount = props.getFrequencyGraphs.use_map[props.searchName][a.value] || 0;
                    let bCount = props.getFrequencyGraphs.use_map[props.searchName][b.value] || 0;
                    return bCount - aCount;
                }));
            }
        }
        setForceRedraw(forceRedraw + 1);

    }, [sortOptionsByRecommended, props.searchName, props.knownUses, props.medsForCondOptions]);

    let [isTogglePaneOpen, setIsTogglePaneOpen] = useState(true);
    let keyboardShortcutType = useRef('keypress');

    useEffect(() => {
        if (!props.searchName || props.searchName.length < 2) {
            setIsTogglePaneOpen(false);
        }
    }, [props.searchName])

    let mappedOptions = options.map((obj) => {
        return <CheckField key={`${obj.id}`} label={obj.label}
            onChange={((e) => {
                let localGetter = props.medChips;
                if (props.medChips.includes(obj.value)) {
                    localGetter = localGetter.filter((x) => x != obj.value);
                    props.setMedChips(localGetter);
                } else {
                    localGetter = localGetter.concat(obj.value);
                    props.setMedChips(localGetter);
                }

                if (props.onChange) props.onChange(e);
            })}></CheckField>
    });

    return (
        <>
            <View style={[Constants.styles.flexRow, { justifyContent: 'right', alignItems: 'center' }]}>
                <View style={[{
                    maxWidth: isMobile ? 214 : 214,
                    minWidth: isMobile ? 214 : 214,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 8,
                }]}>

                    <svg fill="#aaa" height="15px" width="15px" version="1.1" id="filterIco" style={{
                        position: 'absolute',
                        marginLeft: 10,
                        zIndex: 5,
                        marginRight: -15,
                        height: 15,
                        width: 15,
                        minHeight: 15,
                        minWidth: 15
                    }} viewBox="0 0 490 490">
                        <g>
                            <polygon points="85.877,154.014 85.877,428.309 131.706,428.309 131.706,154.014 180.497,221.213 217.584,194.27 108.792,44.46
		0,194.27 37.087,221.213"/>
                            <polygon points="404.13,335.988 404.13,61.691 358.301,61.691 358.301,335.99 309.503,268.787 272.416,295.73 381.216,445.54
		490,295.715 452.913,268.802"/>
                        </g>
                    </svg>

                    <SelectField id={`sort-search`}
                        defaultValue="true"
                        value={sortOptionsByRecommended ? 'true' : 'false'}
                        options={[{ label: 'Most Common', value: 'true', id: 'recommended' },
                        { label: 'A-Z', value: 'false', id: 'alphabetize' }]}
                        onValueChange={(val) => {
                            if (val == 'false') {
                                setSortOptionsByRecommended(false);
                            } else {
                                setSortOptionsByRecommended(true);
                            }
                        }}
                        style={{ background: 'transparent' }}
                        labelStyle={{ paddingLeft: 5 }}
                        isSortField={1}
                        maxWidth={214}

                        getter={sortOptionsByRecommended}
                        setter={setSortOptionsByRecommended}
                    ></SelectField>

                </View>
            </View>
            <Pressable onPress={() => props.setShowMedsForCond(false)} style={{
                zIndex: 9999,
                backgroundColor: Constants.colors.paneColor,
                position: 'absolute',
                right: 20,
                marginTop: 60
            }}>
                <svg width="24" height="24" style={{ marginRight: 0 }} viewBox="0 0 25 25" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                        fill={Constants.colors.captionColor} />
                </svg>
            </Pressable>
            <View style={[isTogglePaneOpen ? styles.infoBoxOpen : styles.infoBoxClosed, isTogglePaneOpen && isMobile ? styles.infoBoxOpenMobile : null, isTogglePaneOpen && !isMobile ? { marginLeft: 0 } : null, isTogglePaneOpen && props.medsForCondOptions ? styles.infoBoxOpenSortable : styles.infoBoxClosed, { marginTop: '2px' }]} key={forceRedraw}>
                {isTogglePaneOpen ?
                    <View style={Constants.styles.flex} key={forceRedraw}>
                        {mappedOptions}
                        <Text>{`[Other medications for ${props.searchName} may be ignored by this version of BestPlanPro.]`}</Text>
                    </View>
                    : null}
                <KeyboardShortcut shortcut="!" type={keyboardShortcutType.current} callback={(e) => {
                    if (props.showMedUses) {
                        keyboardShortcutType.current = 'keyup';
                        props.setShowMedUses(!props.showMedUses);
                        e.preventDefault();
                    }
                }} />
            </View>
        </>

    )

});

const ShowNarrowingOptions = React.memo((props) => {

    if (!props.show) return;
    //if (props.narrowedOptions.length == 0) return;

    let opts = props.narrowedOptions.map((t, idx) => {
        return { label: t, value: t, id: Data.zysys_make_key(t) + `-id-${props.renderCount.current + '-' + idx}` }
    });
    let [selected, setSelected] = useState(props.searchName);
    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    return (
        <View style={[{ marginTop: -1 * Constants.leftOffset }, isMobile ? { width: 409, right: 0, maxWidth: 409 } : { width: 438, maxWidth: 438, right: 0 }]}>
            <View style={[Constants.styles.flexRow, { justifyContent: 'right', marginRight: 30, alignItems: 'center' }]}>
                <View style={[{
                    maxWidth: isMobile ? 214 : 214,
                    minWidth: isMobile ? 214 : 214,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 8,
                }]}>

                    <svg fill="#aaa" height="15px" width="15px" version="1.1" id="filterIco" style={{
                        position: 'absolute',
                        marginLeft: 10,
                        zIndex: 5,
                        marginRight: -15,
                        height: 15,
                        width: 15,
                        minHeight: 15,
                        minWidth: 15
                    }} viewBox="0 0 490 490">
                        <g>
                            <polygon points="85.877,154.014 85.877,428.309 131.706,428.309 131.706,154.014 180.497,221.213 217.584,194.27 108.792,44.46 
		0,194.27 37.087,221.213"/>
                            <polygon points="404.13,335.988 404.13,61.691 358.301,61.691 358.301,335.99 309.503,268.787 272.416,295.73 381.216,445.54 
		490,295.715 452.913,268.802"/>
                        </g>
                    </svg>

                    <SelectField id={`sort-search`}
                        defaultValue="false"
                        value={props.alphabetizeNarrowedOptions ? 'true' : 'false'}
                        options={[{ label: 'Recommended', value: 'false', id: 'recommended' },
                        { label: 'A-Z', value: 'true', id: 'alphabetize' }]}
                        onValueChange={(val) => {
                            if (val == 'false') {
                                props.setAlphabetizeNarrowedOptions(false);
                            } else {
                                props.setAlphabetizeNarrowedOptions(true);
                            }
                        }}
                        style={{ background: 'transparent' }}
                        labelStyle={{ paddingLeft: 5 }}
                        isSortField={1}
                        maxWidth={214}

                        getter={props.alphabetizeNarrowedOptions}
                        setter={props.setAlphabetizeNarrowedOptions}
                    ></SelectField>

                </View>
            </View>
            <Pressable onPress={(e) => {
                if (props.onPress) props.onPress(e);
                props.setShow(false);
            }} style={{
                zIndex: 9999,
                backgroundColor: Constants.colors.paneColor,
                position: 'absolute',
                right: 48,
                marginTop: 60
            }}>
                <svg width="24" height="24" style={{ marginRight: 0 }} viewBox="0 0 25 25" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                        fill={Constants.colors.captionColor} />
                </svg>
            </Pressable>
            <View style={[{
                height: 250,
                maxHeight: 250,
                paddingLeft: Constants.leftOffset,
                paddingRight: Constants.leftOffset,
                borderRadius: Constants.borderRadius,
                borderWidth: 1,
                padding: 20,
                borderColor: Constants.colors.disabledButton,
                margin: 2,
                marginRight: Constants.leftOffset,
                overflow: 'scroll',
                overflowX: 'hidden',
                marginBottom: Constants.leftOffset
            }]}>
                <FastRadio options={opts} getter={props.searchName} setter={props.setSearchName} onPress={() => {
                    props.setShow(false);
                    props.focus.current.focus()
                }} optionGap={15} />
            </View>
        </View>
    )


});

function AddItemWizard(props) {

    if (props.isRegistered && !props.readonly && props.loadedHealthPartsCount != props.totalPartsCount) {
        return <ActivityIndicator
            animating={props.isRegistered && !props.readonly && props.loadedHealthPartsCount != props.totalPartsCount}
            size="large" color={Constants.colors.primaryColorBG} />
    }


    const createMedicationObject = () => {
        return createMedicationObjectFromParams(
            {
                name: searchName,
                use: use,
                last_fill: isOngoing == 'Yes' ? 'ONGOING' : lastFill,
                first_fill: firstFill,
                dosage: dosage ? dosage : null
            });

    }

    const createConditionObject = (hiddenAsterisk, visibleAsterisk) => {
        return createConditionObjectFromParams(
            {
                name: searchName,
                last_treatment: isOngoing == 'Yes' ? 'ONGOING' : lastTreatment,
                was_diagnosed: wasDiagnosed,
                hiddenAsterisk: hiddenAsterisk === undefined ? true : hiddenAsterisk,
                visibleAsterisk: visibleAsterisk ? visibleAsterisk : false
            });

    }


    const [openItem, setOpenItem] = useState(-1); // this represents the id of the item, -1 will represent opening a blank item
    const [formOk, setFormOk] = useState(true); // @TODO should be false by default


    const [addCondition, setAddCondition] = useState(true);
    const [addMedication, setAddMedication] = useState(true);
    const [searchName, setSearchName] = useState('');
    const [type, setType] = useState(null);
    const [allowOverride, setAllowOverride] = useState(true); // should we allow the system to override 

    const [medChips, setMedChips] = useState([]);

    const [eraseField, setEraseField] = useState(false); // a trigger we'll use to erase medChip Entry Field

    /* both conds and meds */
    const [isOngoing, setIsOngoing] = useState(null);

    /* medication states */
    const [use, setUse] = useState('');
    const [dosage, setDosage] = useState('');
    const [lastFill, setLastFill] = useState('');
    const [firstFill, setFirstFill] = useState('');

    /* condition states */
    const [medForCond, setMedForCond] = useState('');
    let medNameFieldRef = useRef(null);
    const [applyToAllMeds, setApplyToAllMeds] = useState(false);
    const [medicationsToProcess, setMedicationsToProcess] = useState([]);
    const [whichToggleOpen, setWhichToggleOpen] = useState(null);
    const [condStates, setCondStates] = useState([]);
    const [lastTreatment, setLastTreatment] = useState('');
    const [wasDiagnosed, setWasDiagnosed] = useState('');
    let chipCounterRef = useRef(0);


    useEffect(() => {
        if (props.openItem != -1) {
            let obj;
            let earlyDetectedType = '';
            if (props.openItem) {
                // Try to find item in medications first
                let medList = [...props.getMedications];
                if (props.medicationSortNewestAtTop == '1') {
                    medList = medList.sort((a, b) => b.index - a.index);
                } else {
                    medList = medList.sort((a, b) => a.index - b.index);
                }
                obj = medList.find(item => item.uuid === props.openItem);

                if (obj) {
                    earlyDetectedType = 'medication';
                } else {
                    // If not found in medications, try conditions
                    let condList = [...props.getConditions];
                    if (props.conditionSortNewestAtTop == '1') {
                        condList = condList.sort((a, b) => b.index - a.index);
                    } else {
                        condList = condList.sort((a, b) => a.index - b.index);
                    }
                    obj = condList.find(item => item.uuid === props.openItem);
                    if (obj) {
                        earlyDetectedType = 'condition';
                    }
                }
            }

            if (obj && obj.hasOwnProperty('med_name')) {
                setSearchName(obj.name());
                setUse(obj.use());
                setDosage(obj.dosage());
                setLastFill(obj.last_fill());
                setFirstFill(obj.first_fill());
                setType('medication');
                setAddMedication(true);
                setAddCondition(false);
                if (['ONGOING', 'NOW', 'HOY'].includes(obj.last_fill().toUpperCase())) {
                    setIsOngoing('Yes');
                } else {
                    setIsOngoing('No');
                }

            } else if (obj && obj.hasOwnProperty('cond_name')) {
                setSearchName(obj.name());
                setLastTreatment(obj.last_treatment());
                setWasDiagnosed(obj.was_diagnosed());
                setType('condition');
                setAddMedication(false);
                setAddCondition(false);
                if (['ONGOING', 'NOW', 'HOY'].includes(obj.last_treatment().toUpperCase())) {
                    setIsOngoing('Yes');
                } else {
                    setIsOngoing('No');
                }
            }
        }
    }, []);

    let useFieldRef = useRef(null);

    let [lastItemTypeSetFor, setLastItemTypeSetFor] = useState();

    useEffect(() => {

        if (!['medication', 'condition'].includes(type)) {
            setFormOk(false);
        }

        try {
            if (type == 'medication') {
                setAddMedication(true);
            } else {
                setAddMedication(false);
            }

            /*
             * if an item is identified, select it.
             * then, if we use a backspace, erase our pre-selection.
             */

            if (allowOverride && lastItemTypeSetFor != searchName) { // prevent user-selected option from being overwritten
                if (props.openItem == -1) {
                    setTypeFromInput(searchName);
                }
            }

            // if no searchName, we should be able to select med or cond


            // if searchName removed, type should be erased
            if (searchName == "" && lastItemTypeSetFor != searchName) {


                /* setType(' ');
                setAllowOverride(true);
                setAddMedication(' ');
                setAddCondition(' ');
                setMedChips([]);
                setMedicationsToProcess([]);*/
            }


        } catch (e) {
            console.log(e)
        }


        if (type == 'medication') {
            setFormOk(true
                && type
                && searchName
                && use
                && (isOngoing || lastFill)
                && firstFill);
        }

    }, [type, searchName, use, isOngoing, lastFill, firstFill]);


    useEffect(() => {
        setLastItemTypeSetFor(searchName);
    }, [type]);

    useEffect(() => {
        if (type == 'medication') {
            setAddMedication(true);
            setAddCondition(false);
        } else if (type == 'condition') {
            setAddMedication(false);
            setAddCondition(true);
        } else {
            setAddMedication('');
            setAddCondition('');
        }
    }, [addMedication, addCondition, type])

    const setTypeFromInput = (inputName) => {
        if (lockedInSearch == inputName) {
            return;
        } else {
            setLockedInSearch(inputName);
        }
        if (inputName == '') {
            setType('');
            setAllowOverride(true);
            setAddMedication('');
            setAddCondition('');
            setMedChips([]);
            setMedicationsToProcess([]);
            return;
        }

        if (props.getMedNamesList.includes(inputName.toUpperCase().trim())) {
            setType('medication');
            setAllowOverride(true);
            setAddMedication(true);
            setAddCondition(false);
        } else if (props.getCondNamesList.includes(inputName.toUpperCase().trim())) {
            setType('condition');
            setAllowOverride(true);
            setAddMedication(false);
            setAddCondition(true);
        }
    }


    const EditApplicableMedications = (props) => {
        if (applyToAllMeds == true) {
            return (
                <></>
            )
        } else {
            return (
                <View key={`edit-meds-${chipCounterRef.current}`}>
                    <View style={[Constants.styles.flexRow, { flexWrap: 'wrap' }, isMobile ? {
                        justifyContent: 'right',
                        marginTop: Constants.leftOffset,
                        marginBottom: 15,
                        marginRight: 30
                    } : { justifyContent: 'space-between', marginTop: Constants.leftOffset }]}>
                        <Text selectable={false} style={Constants.styles.label}>Click each medication name to edit dates
                            and dosages.</Text>

                        {type == 'condition' && medicationsToProcess.length >= 1 && medNameFieldRef.current != '' ?
                            <View style={isMobile ? null : { marginRight: Constants.leftOffset }}>
                                <Pressable style={Constants.styles.flexRow} onPress={() => {
                                    let x = [...medChips, medForCond].filter(e => e);
                                    setMedChips(x);
                                    setMedForCond('');
                                    setEraseField(true);

                                    medNameFieldRef.current.focus()
                                }}>
                                    <Icon as={AntDesign} name="pluscircle" color={Constants.colors.primaryButton}
                                        style={[styles.moreInfoIcon, { marginLeft: 14, marginRight: 14 }]} />
                                    <Text selectable={false} style={{ fontWeight: '500' }}>Add Another Medication</Text>
                                </Pressable>
                            </View>
                            : null}
                    </View>
                    {medicationsToProcess.map(function (med, index) {
                        let states = {};
                        let prevName = '';
                        let prevIsOngoing = '';
                        let prevDosage = '';
                        let prevLastFill = '';
                        let prevFirstFill = '';

                        if (condStates[index]) {
                            prevName = condStates[index].name.getter;
                            prevIsOngoing = condStates[index].isOngoing.getter;
                            prevDosage = condStates[index].dosage.getter;
                            prevLastFill = condStates[index].lastFill.getter;
                            prevFirstFill = condStates[index].firstFill.getter;
                        }


                        let [name, setName] = useState(med);
                        let [use, setUse] = useState(searchName);
                        let [isOngoing, setIsOngoing] = useState(prevIsOngoing);
                        let [dosage, setDosage] = useState(prevDosage);
                        let [lastFill, setLastFill] = useState(prevLastFill);
                        let [firstFill, setFirstFill] = useState(prevFirstFill);
                        states['name'] = { 'getter': name, 'setter': setName };
                        states['use'] = { 'getter': use, 'setter': setUse };
                        states['isOngoing'] = { 'getter': isOngoing, 'setter': setIsOngoing };
                        states['dosage'] = { 'getter': dosage, 'setter': setDosage };
                        states['lastFill'] = { 'getter': lastFill, 'setter': setLastFill };
                        states['firstFill'] = { 'getter': firstFill, 'setter': setFirstFill };
                        condStates[index] = states;
                        setCondStates(condStates);

                        if (states['isOngoing']['getter'] == '') {


                            if (states.lastFill.getter != '') {
                                states.isOngoing.setter('No');
                            } else {
                                if (props.isOngoing != '') {
                                    states['isOngoing']['setter'](props.isOngoing);
                                }
                            }
                        }


                        return (<Pane heading={med} key={`${med}-pane`} headingStyle={{ marginLeft: 0 }} style={[styles.expandablePane]}
                            togglePane={1}
                            whichToggleOpen={med}>
                            <Text selectable={false}
                                style={[Constants.styles.textControlCaption, { marginTop: -30, marginBottom: 30 }]}>Anything
                                left blank will be automatically inferred from the condition.</Text>

                            <AddMedicationDates
                                use={condStates[index].use.getter} setUse={condStates[index].use.setter}
                                isOngoing={condStates[index].isOngoing.getter}
                                setIsOngoing={condStates[index].isOngoing.setter}
                                dosage={condStates[index].dosage.getter} setDosage={condStates[index].dosage.setter}
                                lastFill={condStates[index].lastFill.getter}
                                setLastFill={condStates[index].lastFill.setter}
                                firstFill={condStates[index].firstFill.getter}
                                setFirstFill={condStates[index].firstFill.setter}
                                usePlaceholders={1} lastFillPlaceholder={props.lastTreatment}
                                firstFillPlaceholder={props.wasDiagnosed}
                                internalPane="1" horizontalOngoing={1} readonly={props.readonly} />
                        </Pane>);
                    })
                    }


                </View>
            );
        }
        return (<></>)
    }

    let [narrowedOptions, setNarrowedOptions] = useState([]);
    let [alphabetizeNarrowedOptions, setAlphabetizeNarrowedOptions] = useState(false);
    useEffect(() => {

        let narrowOptions = async () => {
            let newVal = searchName;
            if (newVal.trim() == '') return setNarrowedOptions([]);
            if (newVal.length < 2) return;
            let possibleOptions = [...props.getMedNamesList, ...props.getCondNamesList];
            let wordsInInput = newVal.toUpperCase().split(/[\s]+/).map(x => Data.zysys_clean_str(x)).filter(element => element);
            let setWordsInInput = new Set(wordsInInput);

            if (searchName.length < 3) return;

            let ignoreChars = /\(/g;

            // assume everything is uppercase
            let options = possibleOptions.filter((str) => {
                // transposed words

                // fuzzy variant
                if (wordsInInput.length < 2) {
                    return str.toUpperCase().replace(ignoreChars, '').includes(newVal.toUpperCase().replace(ignoreChars, ''));
                }
                if ((wordsInInput.length - wordsInInput.filter(word => {
                    return str.replace(ignoreChars, '').toUpperCase().replace(/\(/g, '').includes(word)
                }).length) <= Constants.fuzzyCondSearchTolerance) {
                    return true;
                } else {
                    return false;
                }
            });

            /* Autocomplete sorting rules
                                1. starts with at
                                2. only the same words in different orders
                                3. different number of words in increasing count order (no tolerance)
                                4. same number of words (with tolerance)
                                5. different number of words in increasing count order (with tolerance)
                            */

            let ourList = { 'startsWith': [], 'sameWords': [], 'independentWordIntersection': [], 'wordCountNoTolerance': {}, 'sameNumWithTolerance': [], 'wordCountWithTolerance': {} };
            // assume all options are uppercase
            options.forEach(option => {
                // Clean and split the option once
                let cleanedOption = option.replace(ignoreChars, '');
                let wordsInOption = cleanedOption.split(/[\s]+/).map(Data.zysys_clean_str).filter(Boolean);

                // Create a Set for faster lookups
                let setWordsInOption = new Set(wordsInOption);
                let wordsInOptionIsSupersetOfInput = wordsInInput.every(word => setWordsInOption.has(word));

                // Determine the category of the option
                let isStartMatch = cleanedOption.toUpperCase().startsWith(newVal.replace(ignoreChars, '').toUpperCase());
                let isSameLength = wordsInOption.length === wordsInInput.length;
                let lengthDiff = Math.abs(wordsInInput.length - wordsInOption.length);

                if (isStartMatch) {
                    ourList.startsWith.push(option);
                } else if (isSameLength) {
                    if (Data.listsEqual(setWordsInInput, setWordsInOption, true)) {
                        ourList.sameWords.push(option);
                    } else {
                        ourList.sameNumWithTolerance.push(option);
                    }
                } else {
                    if (wordsInOptionIsSupersetOfInput) {
                        if (!ourList.wordCountNoTolerance.hasOwnProperty(lengthDiff)) {
                            ourList.wordCountNoTolerance[lengthDiff] = [];
                        }
                        ourList.wordCountNoTolerance[lengthDiff].push(option);
                    } else {
                        if (!ourList.wordCountWithTolerance.hasOwnProperty(lengthDiff)) {
                            ourList.wordCountWithTolerance[lengthDiff] = [];
                        }
                        ourList.wordCountWithTolerance[lengthDiff].push(option);
                    }
                }

                // Independent intersection check
                if (wordsInInput.every(word => cleanedOption.includes(word))) {
                    ourList.independentWordIntersection.push(option);
                }
            });

            let wordCountKeysWithTolerance = Object.keys(ourList.wordCountWithTolerance);
            wordCountKeysWithTolerance = wordCountKeysWithTolerance.sort();
            let wordCountOptionsWithTolerance = [];
            wordCountKeysWithTolerance.forEach(function (key) {
                wordCountOptionsWithTolerance = wordCountOptionsWithTolerance.concat(ourList.wordCountWithTolerance[key]);
            });

            let wordCountKeysNoTolerance = Object.keys(ourList.wordCountNoTolerance);
            wordCountKeysNoTolerance = wordCountKeysNoTolerance.sort();
            let wordCountOptionsNoTolerance = [];
            wordCountKeysNoTolerance.forEach(function (key) {
                wordCountOptionsNoTolerance = wordCountOptionsNoTolerance.concat(ourList.wordCountNoTolerance[key]);
            });

            // Initialize groupedOptions directly
            let groupedOptions = [
                ourList.startsWith.sort((a, b) => a.split(/\s+/).length - b.split(/\s+/).length),
                ourList.sameWords,
                wordCountOptionsNoTolerance,
                ourList.independentWordIntersection,
                ourList.sameNumWithTolerance,
                wordCountOptionsWithTolerance
            ];

            // Prepare frequency sorted map and scaling factors
            const maxScaleFactor = groupedOptions.length;
            const freqSorted = new Map();
            const orig_groups = [...groupedOptions];
            let foundSomething = false;

            if (props.getFrequencyGraphs && props.getFrequencyGraphs.hasOwnProperty('cond_freq')) {
                // Calculate frequency and scale factor
                groupedOptions.flat().forEach(option => {
                    if (!foundSomething && props.getFrequencyGraphs.cond_freq?.[option]) {
                        foundSomething = true;
                    }
                    const frequency = (props.getFrequencyGraphs.cond_freq[option] || 0) + 1;
                    const groupIndex = groupedOptions.findIndex(group => group.includes(option));
                    const scaleFactor = Math.max(1, maxScaleFactor - groupIndex);
                    freqSorted.set(option, frequency * scaleFactor);
                });

                if (!foundSomething) {
                    // If we didn't find anything, reset the grouped options
                    groupedOptions = orig_groups;
                }

                // Sort each group by frequency
                groupedOptions.forEach(group => {
                    group.sort((a, b) => freqSorted.get(b) - freqSorted.get(a));
                });
            }

            // Concatenate all sorted groups
            let autocompleteOptions = groupedOptions.flat();

            let autocorrected = false;

            if (autocorrected && (ourList.startsWith.length == 0 || (ourList.startsWith.length == 1 && ourList.startsWith[0] == newVal))) {
                autocompleteOptions = [];
            }

            let optionStyle = { margin: 5, padding: 5, fontWeight: '400' }

            // alphabetize
            if (alphabetizeNarrowedOptions)
                autocompleteOptions.sort();

            setNarrowedOptions(Array.from(new Set(autocompleteOptions)));
        };
        narrowOptions();
    }, [searchName, alphabetizeNarrowedOptions])

    let [activeAutocomplete, setActiveAutocomplete] = useState(null);

    let [canSave, setCanSave] = useState(false);
    let [canSaveMessage, setCanSaveMessage] = useState('');

    useEffect(() => {
        if (props.readonly) return;

        let missingFields = [];
        if (!searchName) {
            missingFields.push("Name/Type");
        }
        if (!type
            || !searchName) {
            setCanSaveMessage(missingFields.join(','))
            return setCanSave(false);
        }

        if (type == 'condition') {
            if (!isOngoing && !lastTreatment || (isOngoing == 'No' && !lastTreatment)) {
                missingFields.push("Last Treatment");
                setCanSaveMessage(missingFields.join(','))
                return setCanSave(false);
            }

            // note, wasDiagnosed can be blank for things like Family History

        }

        if (type == 'medication') {
            if (!use) {
                missingFields.push("Prescribed to Treat");
                setCanSaveMessage(missingFields.join(','))
                return setCanSave(false);
            }
            if (!isOngoing && !lastFill || (isOngoing == 'No' && !lastFill)) {
                missingFields.push("Last Fill");
                setCanSaveMessage(missingFields.join(','))
                return setCanSave(false);
            }
            if (!firstFill) {
                missingFields.push("First Fill");
                setCanSaveMessage(missingFields.join(','))
                return setCanSave(false);
            }
        }


        setCanSave(true);
    }, [type, isOngoing, lastTreatment, wasDiagnosed, use, lastFill, firstFill])


    let [knownUses, setKnownUses] = useState([]);
    let [knownUsesOptions, setKnownUsesOptions] = useState([]);
    let [medsForCond, setMedsForCond] = useState([]);
    let [medsForCondOptions, setMedsForCondOptions] = useState([]);
    let [lockedInSearch, setLockedInSearch] = useState(null);
    useEffect(() => {
        if (type == 'medication') {

            let search = searchName.toUpperCase().trim();
            if (!props.getMedList.hasOwnProperty(search)) {
                return setKnownUses([]);
            }
            let list = props.getMedList[search.toUpperCase().trim()].filter(e => e).filter(e => e != 'IRRELEVANT');
            setKnownUses(list);
            let options = [];
            list.forEach((use, idx) => {
                options.push({ label: use, value: use, id: `use-${idx}` })
            });
            setKnownUsesOptions(options);
        } else if (type == 'condition') {

            let search = Data.zysys_make_key(searchName);
            if (search == "" || !props.getMedsByCondList.hasOwnProperty(search)) {
                return setMedsForCond([]);
            }
            let list = props.getMedsByCondList[search].filter(e => e);
            setMedsForCond(list);
            let options = [];
            list.forEach((use, idx) => {
                options.push({ label: use, value: use, id: `med-${idx}` })
            });
            setMedsForCondOptions(options);
            setMedChips([]);
        }
    }, [searchName, type])


    const saveContents = () => {
        if (!canSave) return;


        let meds = props.getMedications;
        let conds = props.getConditions;
        if (type == 'medication') {
            let med = createMedicationObject();
            med.index = props.itemIndex.current;
            props.itemIndex.current += 1;
            if (props.openItem != -1) {
                const medIndex = meds.findIndex(item => item.uuid === props.openItem); // Find index using .uuid
                meds[medIndex] = med;
            } else {
                meds.push(med);
            }
            // don't add this if we have an existing condition that overlaps these dates
            let cond = createConditionObjectFromMedObject(med);

            if (shouldAddTempCondition(cond, conds)) {
                conds.push(cond);
            }
        }

        if (type == 'condition') {

            /* process the base condition */
            let cond = createConditionObject();
            cond.index = props.itemIndex.current;
            props.itemIndex.current += 1;
            if (props.openItem != -1) {
                const condIndex = conds.findIndex(item => item.uuid === props.openItem); // Find index using .uuid
                cond.hasHiddenAsterisk = false;
                cond.hasVisibleAsterisk = false;
                conds[condIndex] = cond;
            } else {
                conds.push(cond);
            }

            if (applyToAllMeds) {
                new Set([...medicationsToProcess]).forEach(function (name, idx) {
                    let medication = condStates[idx];
                    let med_obj = createMedicationObjectFromParams(
                        {
                            name: name,
                            use: cond.name(),
                            last_fill: cond.last_treatment(),
                            first_fill: cond.was_diagnosed(),
                        });
                    meds.push(med_obj);
                });
            } else {

                if (medicationsToProcess.length > 0) {
                    medicationsToProcess.forEach(function (name, idx) {
                        let medication = condStates[idx];
                        let med_obj = createMedicationObjectFromParams(
                            {
                                name: medication.name.getter,
                                use: medication.use.getter,
                                last_fill: medication.isOngoing.getter == 'Yes' ? 'ONGOING' : medication.lastFill.getter,
                                first_fill: medication.firstFill.getter,
                                dosage: medication.dosage.getter ? medication.dosage.getter : null
                            });
                        if (med_obj.last_fill() == '') {
                            med_obj.last_fill(cond.last_treatment());
                        }
                        if (med_obj.first_fill() == '') {
                            med_obj.first_fill(cond.was_diagnosed());
                        }
                        meds.push(med_obj);
                    });
                }
            }

        }

        conds = optimizeTempConditions(conds);
        props.setMedications(meds);
        props.setConditions(conds);
        props.setOpenItemWizard(false);
        props.setOpenItem(-1);
    };

    let isOngoingFieldRef = useRef(null);
    let lastFillFieldRef = useRef(null);
    let firstFillFieldRef = useRef(null);
    let dosageFieldRef = useRef(null);
    let submitButton = useRef(null);

    let renderCount = useRef(0);

    const lf = new Intl.ListFormat('en');

    useEffect(() => {
        renderCount.current++
    });

    let [focusOnSearch, setFocusOnSearch] = useState(true);
    useEffect(() => {
        if (props.focusOn.current == 'search' && props.setOpenItemWizard) {
            if (searchFieldRef.current) searchFieldRef.current.focus();
            setFocusOnSearch(false);
            props.focusOn.current = '';
        }

    }, [props.setOpenItemWizard])

    let [focusOnMedUse, setFocusOnMedUse] = useState(false);
    useEffect(() => {
        if (focusOnMedUse)
            if (useFieldRef.current) useFieldRef.current.focus();
        setFocusOnMedUse(false);
    }, [focusOnMedUse])


    let saveText = `Save and Close`;//${medicationsToProcess.length > 0 ? "These" : "This"}`;
    if (props.openItem != -1) saveText = 'Update This';

    let [showMedUses, setShowMedUses] = useState(false);
    useEffect(() => {
        if (searchName && !showMedUses && useFieldRef.current) {
            useFieldRef.current.focus()
        }
    }, [showMedUses]);

    let [showMedsForCond, setShowMedsForCond] = useState(false);
    useEffect(() => {
        /* if (!showMedsForCond && useFieldRef.current) {
             useFieldRef.current.focus()
         }*/
    }, [showMedsForCond]);

    let searchFieldRef = useRef(null);

    /*let [focusOn, setFocusOn] = useState(props.focusOn.current);
    useEffect(() => {
        if (props.focusOn.current == 'search') {
            if (searchFieldRef.current) {
                searchFieldRef.current.focus();
                props.setFocusOn('');
                setFocusOn('');
            }
        }
    }, [props.focusOn]);*/


    // if this is visible, it should be fully visible, if possible
    // setTimeout(() => { Data.scroll_into_view("wizard", false) }, 100)

    let medChipsRef = useRef([]);
    useEffect(() => {
        medChipsRef.current = medChips;
    }, [medChips])

    let drawCount = useRef(0);
    useEffect(() => {
        drawCount.current++;
    });


    useEffect(() => {
        setMedicationsToProcess(medChips);
    }, [medChips])

    let medOptionsRef = useRef({});

    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let buttonWidth = isMobile ? '70vw' : Constants.styles.actionButton.width;

    let translations = Data.define_translations();
    let autoconds = Data.define_autoconds();
    let medAutoconds = Data.define_med_autoconds();

    useEffect(() => {
        if (Object.keys(translations).includes(searchName.toUpperCase())) {
            setSearchName(translations[searchName.toUpperCase()]);
        }
        if (Object.keys(translations).includes(use.toUpperCase())) {
            setUse(translations[use.toUpperCase()]);
        }
    }, [searchName, use])


    let [showNarrowingOptions, setShowNarrowingOptions] = useState(false);
    return (
        <Pane
            id="wizard"
            onLayout={() => {
            }}
            heading={props.openItem == -1 ? 'Health & Underwriting Wizard' : 'Health & Underwriting Wizard'}
            headingStyle={{
                borderBottomWidth: 0,
                borderColor: Constants.colors.paneColor
            }}
            secondaryIcon={
                <svg aria-label="Close Wizard" width="24" height="24" style={{ marginRight: 48 }} viewBox="0 0 25 25" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                        fill={Constants.colors.captionColor} />
                </svg>
            }
            secondaryPress={() => {
                props.setOpenItem(-1);
                props.setOpenItemWizard(false)
            }}
            style={{
                zIndex: 10,
                borderWidth: 1,
                borderRadius: 0,
                borderStyle: props.openItem == -1 ? 'dashed' : 'solid',
                borderColor: Constants.colors.primaryButton,
                marginTop: 0,
                marginLeft: Constants.leftOffset * -1,
            }}>
            <Row label={showNarrowingOptions ? "Search" : "Name"} activeAutocompleteID="search"
                activeAutocomplete={activeAutocomplete} setActiveAutocomplete={setActiveAutocomplete}
                containsAutocomplete={true} labelIcon={
                    <IconButton icon={<Icon as={FontAwesome} name="question-circle-o" color={Constants.colors.label}
                        style={[styles.moreInfoIcon, { marginLeft: 0 }]} />} />
                }
                labelIconTooltip="Enter the name of the medication or condition you'd like to add (autocomplete & typo correction will assist)">
                <View style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}><TextInputField
                    activeAutocompleteID="search" activeAutocomplete={activeAutocomplete}
                    testID="item-name"
                    setActiveAutocomplete={setActiveAutocomplete}
                    frequencyGraphs={props.getFrequencyGraphs}
                    prefixAdornment='search' correctTypos={props.getTyposList}
                    style={[Constants.styles.textControl, { textTransform: 'uppercase' }]} val={searchName}
                    syncWithGetter={1} getter={searchName} value={searchName} setter={(val) => { setSearchName(val.toUpperCase()); }}
                    autocomplete={showNarrowingOptions ? null : [...props.getMedNamesList, ...props.getCondNamesList]}
                    refVal={searchFieldRef} optionsNarrowed={showNarrowingOptions}
                    nextFieldFocuser={() => {
                        if (type == 'medication') {
                            useFieldRef.current.focus();
                        }
                    }}
                    onAutocompleteSelect={(selectedName) => {
                        setTypeFromInput(selectedName);

                        // type may not be set at this point, hence the wrapping in try catch
                        setTimeout(() => {
                            try {
                                useFieldRef.current.focus();
                            } catch (e) { /* prob not a med */
                            }
                        }, 100);

                        return /* this is here so nextFieldFocuser triggers on autocomplete click */
                    }}
                    onKeyPress={(e) => {
                        if (e.key == '!') {
                            setShowNarrowingOptions(!showNarrowingOptions)
                            e.preventDefault();
                            e.target.value.replace('!', '');
                            setSearchName(searchName.replace('!', ''));
                            return e.preventDefault();
                        }
                        return;
                        if (e.key == 'Escape') {
                            props.setOpenItem(-1);
                            props.setOpenItemWizard(false);
                        }
                    }}
                    onEscape={(e) => {
                        // this will only fire if ESC and autocomplete is closed
                        props.setOpenItem(-1);
                        props.setOpenItemWizard(false);
                    }}
                    onErase={(e) => {
                        setType(' ');
                    }}
                    readonly={props.readonly}
                ></TextInputField>
                    {props.isRegistered && !props.readonly ?
                        <Pressable style={{ marginLeft: 30, zIndex: -5, marginTop: 10, marginBottom: 10 }}
                            onPress={() => setShowNarrowingOptions(!showNarrowingOptions)}>
                            <Text selectable={false} readonly={props.readonly}
                                style={Constants.styles.link}>{showNarrowingOptions ? 'Hide search results' : 'Show search results'}</Text>
                        </Pressable> : null}
                </View>


            </Row>

            {showNarrowingOptions ? null :
                <Row label='' style={isMobile ? { marginTop: -45 } : { marginTop: -20 }}
                    bottomSpacing={Constants.rowMarginBottom * .7}>
                    <RadioField readonly={props.readonly} onChange={() => { setLockedInSearch(searchName) }} getter={type} setter={setType} name="itemType" id="itemType"
                        horizontal={1} activeLabelStyle={{ fontWeight: 500 }} options={
                            [{ label: 'Condition', value: 'condition', id: 'typeCondition' },
                            { label: 'Medication', value: 'medication', id: 'typeMedication' }]}
                        optionStyle={[Constants.styles.flexRow, { flex: 1, justifyContent: 'flex-start' }]}
                        style={[Constants.styles.flexRow, {
                            flex: 1,
                            justifyContent: 'space-between'
                        }]}></RadioField>
                </Row>
            }

            {showNarrowingOptions ? <Row label='' style={[{ marginTop: '2rem' }]}
                bottomSpacing={Constants.rowMarginBottom * .7}>
                <ShowNarrowingOptions {...props} alphabetizeNarrowedOptions={alphabetizeNarrowedOptions}
                    setAlphabetizeNarrowedOptions={setAlphabetizeNarrowedOptions}
                    searchFieldRef={searchFieldRef} searchName={searchName} setSearchName={(val) => { setSearchName(val.toUpperCase()); }}
                    renderCount={renderCount}
                    autocomplete={[...props.getMedNamesList, ...props.getCondNamesList]}
                    narrowedOptions={narrowedOptions} show={showNarrowingOptions}
                    setShow={setShowNarrowingOptions} />
            </Row> : null
            }

            {
                type == 'medication' ?
                    <Row label="Prescribed to Treat"
                        leftPart={[{ marginTop: 10 }, showMedUses ? { marginRight: Constants.leftOffset * -.7 } : null]}
                        style={[{ alignItems: 'flex-start' }]}
                        containsAutocomplete={1} activeAutocompleteID="use"
                        activeAutocomplete={activeAutocomplete} setActiveAutocomplete={setActiveAutocomplete}>


                        <View style={[Constants.styles.flex]}>
                            {showMedUses && props.isRegistered && !props.readonly ?
                                <MedUses getFrequencyGraphs={props.getFrequencyGraphs}
                                    useFieldRef={useFieldRef} knownUses={knownUses}
                                    knownUsesOptions={knownUsesOptions} searchName={searchName} setUse={setUse}
                                    use={use} setShowMedUses={setShowMedUses} showMedUses={showMedUses} />
                                : <TextInputField refVal={useFieldRef} activeAutocompleteID="use"
                                    activeAutocomplete={activeAutocomplete}
                                    setActiveAutocomplete={setActiveAutocomplete} prefixAdornment='search'
                                    correctTypos={props.getTyposList}
                                    style={[Constants.styles.textControl, { textTransform: 'uppercase' }]}
                                    getter={use} setter={setUse} value={use}
                                    autocomplete={props.getCondNamesList}
                                    onChangeText={(val) => {
                                        if (val.includes('!')) {
                                            setShowMedUses(true);
                                            setUse(val.replace(/!/g, ''));

                                            e.target.value = e.target.value.replace(/!/g, '');
                                            return false;
                                        }
                                    }}
                                    readonly={props.readonly}
                                ></TextInputField>
                            }
                            {!props.isRegistered || props.readonly ? null : searchName.trim() != '' ?
                                knownUses.length > 0 ?
                                    <Pressable style={[Constants.styles.flexRow, { marginTop: 10, zIndex: -5 }]}
                                        onPress={(() => {
                                            setShowMedUses(!showMedUses);
                                            setFocusOnMedUse(true);
                                        })}><Text selectable={false} style={{
                                            marginLeft: 14,
                                            fontSize: Constants.fontSizes.input,
                                            color: Constants.colors.captionColor,
                                            fontWeight: '500'
                                        }}>or <Text selectable={false}
                                            style={[Constants.styles.link, { marginLeft: 14 }]}>{showMedUses ? `Enter a different impairment` : `See related impairments`}</Text></Text></Pressable>
                                    : <Text selectable={false} style={{
                                        marginLeft: 14,
                                        marginTop: 10,
                                        fontSize: Constants.fontSizes.caption,
                                        fontStyle: 'italic',
                                        maxWidth: Constants.controlMaxWidth,
                                        zIndex: -1,
                                    }}>No related impairments of {searchName} can be displayed at this time.</Text>
                                : null}
                        </View>

                    </Row> : null
            }

            {
                type == 'condition' ? <AddConditionDates
                    isOngoing={isOngoing} setIsOngoing={setIsOngoing}
                    lastTreatment={lastTreatment} setLastTreatment={setLastTreatment}
                    wasDiagnosed={wasDiagnosed} setWasDiagnosed={setWasDiagnosed} readonly={props.readonly}

                /> : null
            }

            {
                type == 'condition' && props.isRegistered && !props.readonly ? <>
                    <>
                        <Row activeAutocompleteID="medChips"
                            activeAutocomplete={activeAutocomplete} setActiveAutocomplete={setActiveAutocomplete}
                            label="Quick Add Med(s)" showOptional={1}
                            iconStyle={{ marginTop: 15, position: 'absolute' }}
                            correctTypos={props.getTyposList} containsAutocomplete={1}
                            style={{ alignItems: 'flex-start' }} labelIcon={
                                <IconButton
                                    icon={<Icon as={FontAwesome} name="question-circle-o" color={Constants.colors.label}
                                        style={[styles.moreInfoIcon, { marginLeft: 0 }]} />} />

                            }
                            labelIconTooltip="Quickly add additional medications for this condition to the health profile instead of adding one medication at a time.">

                            <View style={[Constants.styles.flex, { marginBottom: Constants.leftOffset / 2 }]}>
                                {showMedsForCond ?
                                    <>
                                        <MedsForCond getFrequencyGraphs={props.getFrequencyGraphs}
                                            optionsRef={medOptionsRef}
                                            medChips={medChips} setMedChips={setMedChips}
                                            medicationsToProcess={medicationsToProcess}
                                            setMedicationsToProcess={setMedicationsToProcess}
                                            medsForCond={medsForCond} medsForCondOptions={medsForCondOptions}
                                            searchName={searchName} setShowMedsForCond={setShowMedsForCond}
                                            showMedsForCond={showMedsForCond} />
                                    </> : null
                                }
                            </View>
                            <View style={[{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap'
                            }, { alignContent: 'center', }]}>
                                <TextInputField key={`addmed-chip-${chipCounterRef.current}`} isExpanding={1}
                                    activeAutocompleteID="medChips" iconStyle={{ marginTop: 13 }}
                                    iconStyleWrap={{ alignItems: 'flex-start', alignContent: 'flex-start' }}
                                    activeAutocomplete={activeAutocomplete}
                                    setActiveAutocomplete={setActiveAutocomplete}
                                    prefixAdornment='search'
                                    refVal={(input) => medNameFieldRef.current = input}
                                    focusAfterAutocomplete={true} autocomplete={props.getMedNamesList}
                                    getter={medForCond} setter={setMedForCond}
                                    style={Constants.styles.textControl} chips={medChips}
                                    setChips={setMedChips}
                                    ref={medNameFieldRef}
                                    onAutocompleteSelect={(val) => {
                                        let x = [...medChips, val].filter(e => e);
                                        setMedChips(x);
                                        setMedForCond('');
                                        setMedicationsToProcess(x);
                                        setEraseField(true);
                                        chipCounterRef.current++;
                                    }}

                                    onChipDelete={(e, currentChips) => {
                                        setMedicationsToProcess(currentChips)
                                    }}
                                    nextFieldFocuser={() => {
                                        setTimeout(() => {
                                            medNameFieldRef.current.focus();
                                        }, 50);
                                    }}

                                    autocompleteFromStartOnly={1} eraseField={eraseField}
                                    setEraseField={setEraseField}
                                    chipField={1} medChips={medChips} setMedChips={setMedChips}
                                    medForCond={medForCond} setMedForCond={setMedForCond}
                                    correctTypos={props.getTyposList}
                                    setMedicationsToProcess={setMedicationsToProcess}
                                    onKeyPress={(e) => {
                                        // this is now done in the input logic

                                        /* if (e.key == 'Enter' || e.key == ',') {
                                             let x = [...medChips, medForCond];
                                             setMedChips(x);
                                             setMedForCond('');
                                             e.target.value = '';
                                             try { medNameFieldRef.current.focus(); } catch (e) { }
                                             setEraseField(true);
                                             e.preventDefault();
            
                                         }
                                         if (medForCond != '') {
                                             setMedicationsToProcess([...medChips, medForCond].filter(element => element))
                                         } else {
                                             setMedicationsToProcess([...medChips].filter(element => element))
                                         }*/
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value != '') {
                                            setMedicationsToProcess([...medChips, e.target.value].filter(element => element))
                                        } else {
                                            setMedicationsToProcess([...medChips].filter(element => element))
                                        }

                                    }}
                                    readonly={props.readonly}
                                />
                                <Pressable isDisabled={props.readonly} style={{ marginTop: 13 }} onPress={() => {
                                    let x = [...medChips, medForCond].filter(e => e);
                                    setMedChips(x);
                                    setMedForCond('');
                                    setEraseField(true);
                                    try {
                                        medNameFieldRef.current.focus();
                                    } catch (e) {
                                    }
                                }}>
                                    <Icon as={AntDesign} name="pluscircle" color={Constants.colors.primaryButton}
                                        style={[styles.moreInfoIcon, { marginLeft: 14 }]} />
                                </Pressable>
                                {showMedsForCond ? null : <Pressable
                                    style={[Constants.styles.flex, Constants.styles.verticalCenter, {
                                        zIndex: -5,
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        marginTop: 13,
                                        marginLeft: Constants.leftOffset / 2,
                                        flexWrap: 'wrap'
                                    }]} onPress={() => {
                                        setShowMedsForCond(true)
                                    }}><Text selectable={false}
                                        style={[Constants.styles.textControlCaption, { marginLeft: Constants.leftOffset / 2 }]}>or<Text
                                            selectable={false}
                                            style={[Constants.styles.link, { marginLeft: Constants.leftOffset / 2 }]}>{`Show options`}</Text></Text></Pressable>}
                            </View>
                        </Row>
                    </>
                    {props.openItem != -1 && props.getMedications.filter(e => e.use() == searchName).length >= 1 ?
                        <Text selectable={false} style={{
                            paddingRight: Constants.leftOffset,
                            fontStyle: 'italic',
                            color: Constants.colors.captionColor
                        }}>
                            Please, note you can add more medications for {searchName}, but you can't update the ones
                            you added here on this page.
                            To edit {
                                props.getMedications.filter(e => e.use() == searchName)
                                    .map((e, idx) =>
                                        <Text>
                                            <Text selectable={false}
                                                style={{ fontStyle: 'normal', fontWeight: '500' }}>{e.name()}</Text>
                                            {idx != props.getMedications.filter(e => e.use() == searchName).length - 1 ?
                                                idx == props.getMedications.filter(e => e.use() == searchName).length - 2 ?
                                                    props.getMedications.filter(e => e.use() == searchName).length == 2 ? ' or ' : ', or '
                                                    : ', '
                                                : null}</Text>
                                    )}
                            , click the pencil icon next to that medication on the health profile.
                        </Text>
                        : null}
                </>
                    : null
            }

            {
                type == 'condition' && medicationsToProcess.length > 0 ?
                    <EditApplicableMedications key={`edit-${renderCount.current}`} isOngoing={isOngoing}
                        lastTreatment={lastTreatment} wasDiagnosed={wasDiagnosed} />
                    : null
            }

            {
                addMedication ? <AddMedicationDates
                    use={use} setUse={setUse}
                    isOngoing={isOngoing} setIsOngoing={setIsOngoing}
                    dosage={dosage} setDosage={setDosage}
                    lastFill={lastFill} setLastFill={setLastFill}
                    firstFill={firstFill} setFirstFill={setFirstFill}
                    isOngoingFieldRef={isOngoingFieldRef}
                    lastFillFieldRef={lastFillFieldRef}
                    firstFillFieldRef={firstFillFieldRef}
                    dosageFieldRef={dosageFieldRef} readonly={props.readonly}

                /> : null
            }
            <View style={Constants.styles.flex}>
                {props.isRegistered && !props.readonly ?
                    <Box style={[styles.actionButtonsWrapper, isMobile ? { flexDirection: 'column-reverse', justifyContent: 'center', alignItems: 'center', marginRight: Constants.leftOffset } : null]}
                        nativeId="wizard" id="wizard">

                        <Pressable id="cancel" onPressOut={() => {
                            props.setOpenItem(-1);
                            props.setLastAction('cancel');
                            props.setOpenItemWizard(false)
                        }}
                            style={[Constants.styles.actionButton, Constants.styles.secondaryButton, {
                                justifyContent: 'center',
                                marginBottom: 10,
                                width: buttonWidth
                            }]}>
                            <Text selectable={false} style={Constants.styles.secondaryButtonText}>Cancel</Text>
                        </Pressable>
                        {canSave ?
                            <Pressable id="saveAndAdd"
                                style={canSave ? [Constants.styles.actionButton, Constants.styles.tertiaryButton, {
                                    justifyContent: 'center',
                                    marginBottom: 10,
                                    width: buttonWidth
                                }] : [Constants.styles.actionButton, Constants.styles.disabledButton, {
                                    justifyContent: 'center',
                                    marginBottom: 10
                                }]}
                                onPress={() => {
                                    props.setFocusOn('search');
                                    saveContents();
                                    props.setOpenItemWizard(false);
                                    props.setFocusOn('search');
                                    props.setLastAction('save-and-add');
                                    setTimeout(() => {
                                        // @TODO find out why we need an arbitrary time delay...
                                        props.setFocusOn('search');
                                        setOpenItem(-1);
                                        props.setOpenItemWizard(true);
                                        props.setFocusOn('search');
                                        Data.scroll_into_view("wizard");
                                    }, 75);
                                }}
                            >
                                <Text selectable={false} ref={submitButton}
                                    style={canSave ? Constants.styles.tertiaryButtonText : Constants.styles.disabledButtonText}>{saveText.replace(/ (This|These|and Close)/, ' and Add')}</Text>
                            </Pressable>
                            : null}
                        <Pressable id="save"
                            style={canSave ? [Constants.styles.actionButton, Constants.styles.primaryButton, {
                                justifyContent: 'center',
                                marginBottom: 10,
                                width: buttonWidth
                            }] : [Constants.styles.actionButton, Constants.styles.disabledButton, {
                                justifyContent: 'center',
                                marginBottom: 10,
                                width: buttonWidth
                            }]}
                            onPress={() => {
                                props.setLastAction('save-and-close');
                                saveContents();
                            }}
                        >
                            <Text selectable={false} ref={submitButton}
                                style={canSave ? Constants.styles.primaryButtonText : Constants.styles.disabledButtonText}>{saveText}</Text>
                        </Pressable>

                    </Box> :

                    <Box style={[styles.actionButtonsWrapper, isMobile ? { flexDirection: 'column-reverse' } : null]}
                        nativeId="wizard" id="wizard">

                        <Pressable id="cancel" onPressOut={() => {
                            props.setOpenItem(-1);
                            props.setOpenItemWizard(false)
                            props.setLastAction('cancel');
                        }}
                            style={[Constants.styles.actionButton, Constants.styles.secondaryButton, {
                                justifyContent: 'center',
                                marginBottom: 10,
                                width: buttonWidth
                            }]}>
                            <Text selectable={false} style={Constants.styles.secondaryButtonText}>Close</Text>
                        </Pressable>

                    </Box>
                }

                {!canSave && !props.readonly ?
                    <View style={[Constants.styles.flex, {
                        alignSelf: 'flex-end',
                        marginTop: -30,
                        paddingRight: 43,
                        justifyContent: 'right',
                        textAlign: 'right'
                    }]}>
                        <Text selectable={false} style={[Constants.styles.caption, {
                            fontSize: Constants.fontSizes.caption,
                            paddingTop: 5,
                            paddingBottom: 10,
                            paddingRight: 5
                        }]}>Missing: {canSaveMessage}</Text>
                    </View>
                    : null}
                {canSave && medicationsToProcess.length > 0 && searchName ?
                    <View style={[Constants.styles.flex, {
                        alignSelf: 'flex-end',
                        marginTop: -30,
                        paddingRight: 43,
                        justifyContent: 'right',
                        textAlign: 'right'
                    }]}>
                        <Text selectable={false} style={[Constants.styles.caption, {
                            fontSize: Constants.fontSizes.caption,
                            paddingTop: 5,
                            paddingBottom: 10,
                            paddingRight: 5
                        }]}>This will save {[searchName].concat(medicationsToProcess).map((x, idx) => {
                            return <Text><Text selectable={false} style={{ fontWeight: '500' }}>{x.toUpperCase()}</Text>
                                {idx == medicationsToProcess.length - 1 /* base 1 and we made our list base 1 with searchName */ ?
                                    (medicationsToProcess.length == 1 ? ' and ' : ', and ')
                                    : (idx == medicationsToProcess.length ? null : ', ')}</Text>
                        })}</Text>
                    </View>
                    : null}
            </View>

            <KeyboardShortcut moddedShortcut='Escape' callback={() => {
                props.setOpenItem(-1);
                props.setOpenItemWizard(false);
            }} />
            <KeyboardShortcut shortcut='Enter' callback={() => {
                if (activeAutocomplete) {
                    return;
                }
                if (!canSave) {
                    // focus on the next element
                    try {
                        if (searchName != '' && use == '') {
                            useFieldRef.current.focus();
                        }
                        if (searchName != '' && use != '' && isOngoing == '') {
                            isOngoingFieldRef.current.focus();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
                return saveContents();
            }} />
        </Pane >);
}

export default AddItemWizard


const showNicotineOptionsLinkTopSpacing = 14;
const styles = new StyleSheet.create({
    infoBoxOpen: {
        overflowY: 'scroll',
        borderRadius: Constants.borderRadius,
        borderWidth: 1,
        padding: 20,
        height: 200,
        width: 300,
        marginLeft: 20,
        maxWidth: 409,
        borderColor: Constants.colors.disabledButton,
    },
    infoBoxOpenMobile: {
        marginLeft: 2,
        maxWidth: 377,
        width: 377,
    },
    infoBoxOpenSortable: {
        width: '100%',
    },
    infoBoxClosed: {
        overflowY: 'scroll',
        borderRadius: Constants.borderRadius,
        borderWidth: 1,
        padding: 20,
        height: 60,
        width: 300,
        marginLeft: Constants.leftOffset * -.7,
        borderColor: Constants.colors.disabledButton,
    },
    addButton: {
        fontSize: Constants.fontSizes.input,
        fontWeight: '700',
        color: Constants.colors.primaryButton,
        marginRight: 40,

        marginLeft: 8,
    },
    actionButtonsWrapper: {
        flex: 1,
        textAlign: 'right',
        flexDirection: 'row',
        alignContent: 'right',
        alignItems: Platform.OS !== 'web' ? 'center' : 'right',
        justifyContent: Platform.OS !== 'web' ? 'center' : 'right',
        marginBottom: 32,
        marginRight: 42,
        marginTop: 30,
        flexWrap: 'wrap',
    },
    moreInfoIcon: {
        height: 12,
        width: 12,
        pointer: 'cursor',
        marginLeft: 5,
    },
    expandablePane: {
        marginTop: 6,
    },


});

/* need to add actual custom css:
TextInputField[type=radio]
    background-color: // blue
*/


function optimizeTempConditions(conditions) {

    let optimizedList = [];
    let force_star = false;
    let force_update = false;

    let replacement_conditions = {};
    let conditionCounts = {}
    conditions.forEach(function (cond) {
        let name = cond.name();
        if (!conditionCounts.hasOwnProperty(name)) {
            conditionCounts[name] = 0;
        }
        if (cond.hasHiddenAsterisk) {
            conditionCounts[name]++;
        }
    });

    Object.keys(conditionCounts).forEach(searchName => {
        if (conditionCounts[searchName] == 1) return;
        let relevantConds = conditions.filter((element) => {
            return ((element.name() == searchName) && element.hasHiddenAsterisk)
        });


        if (relevantConds.length == 1) return;
        let new_cond = relevantConds[0];

        let has_changed = false;
        relevantConds.forEach((ocond) => {
            has_changed = false;
            do {
                let new_last_treatment = ocond.last_treatment();
                let new_was_diagnosed = ocond.was_diagnosed();
                let lt_has_changed = false;
                let wd_has_changed = false;
                has_changed = false
                if (force_update || Data.date_process(new_cond.last_treatment()) < Data.date_process(new_last_treatment)) {
                    new_cond.last_treatment(new_last_treatment);
                    lt_has_changed = true;
                    has_changed = true;
                }
                if (force_update || Data.date_process(new_cond.was_diagnosed()) > Data.date_process(new_was_diagnosed)) {
                    new_cond.was_diagnosed(new_was_diagnosed);
                    wd_has_changed = true;
                    has_changed = true;
                }
            } while (has_changed == true);

        });


        // replace the relevant conds with the new cond
        conditions.forEach((element, idx) => {
            if ((element.name() == searchName) && element.hasHiddenAsterisk) {

                conditions[idx] = new_cond;
            }
        });
    });

    // dedup
    let objects = {}
    let ids = [];


    conditions.forEach(function (cond, idx) {
        let id = `${cond.name()}${cond.last_treatment()}${cond.was_diagnosed()}`;
        if (!objects.hasOwnProperty(id)) {
            objects[id] = cond;
            ids.push(id);
        }
    });


    conditions = ids.map(function (id) {
        return objects[id]
    });

    return conditions;
}

function shouldAddTempCondition(proposed, conditions) {

    let relevantConds = conditions.filter((element) => {
        return (element.name() == proposed.name() && element.hasHiddenAsterisk == false && element.hasVisibleAsterisk == false)
    });
    let shouldAdd = false;
    if (relevantConds.length == 0) {
        shouldAdd = true;
    }


    let proposed_last_treatment = Data.date_process(proposed.last_treatment());
    let proposed_was_diagnosed = Data.date_process(proposed.was_diagnosed());
    relevantConds.forEach(function (ocond) {

        // if the new last treatment is newer than the known treatment
        if (Data.date_process(ocond.last_treatment()) < proposed_last_treatment) {
            shouldAdd = true;
            return true;
        }

        // if the new was diagnosed is older than the known treatment
        if (Data.date_process(ocond.was_diagnosed()) > proposed_was_diagnosed) {
            shouldAdd = true;
            return true;
        }
    });

    // if the user already saved a condition, we don't need to add any conditions via autocond migration
    if (conditions.filter((element) => {
        return (element.name() == proposed.name() && !element.hasHiddenAsterisk)
    }).length > 0) {
        shouldAdd = false;
    }

    return shouldAdd;

}
