import * as Data from './data';
import { v4 as uuidv4 } from 'uuid';

export function Medication() {
    this.name = function (arg) {
        if (arg) {
            this.med_name = arg.toUpperCase();
        }
        return this.med_name;
    };
    this.use = function (arg) {
        if (arg) {
            this.med_use = arg.toUpperCase();
        }
        return this.med_use;
    };
    this.dosage = function (arg) {
        if (arg) {
            this.med_dosage = arg.toUpperCase();
        }
        return this.med_dosage;
    };
    this.last_fill = function (arg) {
        if (arg) {
            this.med_last_fill = arg.toUpperCase();
        }
        return this.med_last_fill;
    };

    this.first_fill = function (arg) {
        if (arg) {
            this.med_first_fill = arg.toUpperCase();
        }
        return this.med_first_fill;
    };
    this.get_core_args = function () {
        var med = this;
        var args = ['name', 'use', 'dosage', 'last_fill', 'first_fill'];
        var returnString = '(';
        args.forEach(function (element) {

            var right_side = Data.get_formatted_date(med['med_' + element]);
            if (right_side == med['med_' + element]) {
                right_side = Data.zysys_url_clean_str(right_side);
            }
            if (element != 'dosage') {
                returnString += '' + Data.zysys_url_clean_str(element) + '=>"' + right_side + '",'
            } else {
                returnString += '' + Data.zysys_url_clean_str(element) + '=>"' + med['med_' + element] + '",'
            }
        });
        returnString += '),';
        return returnString;
    }

    this.are_dates_valid = function () {
        if (Data.date_process(this.first_fill()) == 'Invalid Date') return false;
        if (Data.date_process(this.last_fill()) == 'Invalid Date') return false;

        return true;
    }

    this.get_date_error_msg = function () {
        if (Data.date_process(this.last_fill()) == 'Invalid Date') {
            return 'Make sure that you enter a valid date (MM/DD/YYYY) or you have the timeframe for the last fill date.  Entering something like `2` should probably be entered as `02/1/2020` or `2 years ago`, for example.';
        }
        if (Data.date_process(this.first_fill()) == 'Invalid Date') {
            return 'Make sure that you enter a valid date (MM/DD/YYYY) or you have the timeframe for the first fill date.  Entering something like `2` should probably be entered as `02/1/2020` or `2 years ago`, for example.';
        }


    }

    this.med_name = '';
    this.med_use = '';
    this.med_dosage = '';
    this.med_last_fill = '';
    this.med_first_fill = '';
    this.index = '';
    this.uuid = uuidv4();
}


export function Condition() {
    this.name = function (arg) {
        if (arg) {
            this.cond_name = arg.toUpperCase();
        }
        return this.cond_name;
    };
    this.last_treatment = function (arg) {
        if (arg) {
            this.cond_last_treatment = arg.toUpperCase();
        }
        return this.cond_last_treatment;
    };

    this.was_diagnosed = function (arg) {
        if (arg) {
            this.cond_was_diagnosed = arg.toUpperCase();
        }
        return this.cond_was_diagnosed;
    };
    this.get_core_args = function () {
        var cond = this;
        var args = ['name', 'last_treatment', 'was_diagnosed'];
        var returnString = '(';
        args.forEach(function (element) {
            var right_side = Data.get_formatted_date(cond['cond_' + element]);
            if (right_side == cond['cond_' + element]) {
                right_side = Data.zysys_url_clean_str(right_side);
            }
            returnString += '' + element + '=>"' + right_side + '",'
        });
        returnString += '),';
        return returnString;
    }

    this.cond_name = '';
    this.cond_last_treatment = '';
    this.cond_was_diagnosed = '';
    this.hasVisibleAsterisk = false;
    this.hasHiddenAsterisk = false;
    this.ExaminationCount = 0;
    this.uuid = uuidv4();
    this.relatedMeds = [];

    this.init_cond = function (cond_name, allCondNames) {
        this.hasVisibleAsterisk = false;
        this.hasHiddenAsterisk = true;

        var result = [];
        allCondNames.forEach(function (item) {
            if (item.indexOf(cond_name) == 0) {
                result.push(item);
            }
        });

        if (result.length > 1) {
            this.hasVisibleAsterisk = true;
            this.hasHiddenAsterisk = false;
        }
    }
    this.is_finalized = function () {
        if (this.hasHiddenAsterisk == false && this.hasVisibleAsterisk == false) {
            return true;
        }

        return false;
    }

    this.should_show_asterisk = function () {
        if (this.is_finalized()) {
            return false;
        }

        /*
        if (this.relatedMeds.length > 1) {
             this.hasVisibleAsterisk = true;
             this.hasHiddenAsterisk = false;
             return true;
         }*/

        return this.hasVisibleAsterisk;
    };

    this.remove_hidden_asterisk = function () {
        this.hasHiddenAsterisk = false;
    }

    this.make_asterisk_visible = function () {
        this.hasVisibleAsterisk = true;
        this.hasHiddenAsterisk = false;
    }

    this.finalize = function () {
        this.hasVisibleAsterisk = false;
        this.hasHiddenAsterisk = false;
    }

    this.add_related_med = function (idx) {
        this.relatedMeds.push(idx);
        this.relatedMeds = this.relatedMeds.unique();
    }

    this.remove_related_med = function (idx) {
        var new_related = [];
        this.relatedMeds.forEach(function (x) {
            if (x != idx) {
                if (x > idx) {
                    new_related.push(x - 1);
                } else {
                    new_related.push(x);
                }
            }
        });
        this.relatedMeds = new_related;
    }

    this.is_related_med = function (idx) {
        return this.relatedMeds.includes(idx);
    }
    this.are_dates_valid = function () {
        if (Data.date_process(this.last_treatment()) == 'Invalid Date') return false;
        if (Data.date_process(this.was_diagnosed()) == 'Invalid Date') return false;

        return true;
    }
    this.get_date_error_msg = function () {
        if (Data.date_process(this.last_treatment()) == 'Invalid Date') {
            return 'Make sure that you enter a valid date (MM/DD/YYYY) or you have the timeframe for the last treatment date.  Entering something like `2` should probably be entered as `2/1/2020` or `2 years ago`, for example.';
        }
        if (Data.date_process(this.was_diagnosed()) == 'Invalid Date') {
            return 'Make sure that you enter a valid date (MM/DD/YYYY) or you have the timeframe for the diagnosis date.  Entering something like `2` should probably be entered as `2/1/2020` or `2 years ago`, for example.';
        }

    }
}

Date.prototype.addDays = function (days) {
    // https://stackoverflow.com/questions/563406/add-days-to-javascript-date/34017571
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}



export function Person() {

    this.license = '';
    this.EULA = '';
    this.piracy_notice = '';

    this.meds = [];
    this.conds = [];
    this.dob = '';
    this.height = '';
    this.weight = '';
    this.sex = '';
    this.state = '';
    this.face_value = '';
    this.monthly_budget = '';
    this.nicotine = '';
    this.nicotine_months = '';

    this.nicotine_usage = {};

    this.zip = '';
    this.setOpenEnrollment = false;

    this.add_med = function (med) {
        this.meds.push(med);
    };

    this.set_nicotine_type = function (name, window) {
        this.nicotine_usage[name] = window;
    };

    this.add_cond = function (cond) {
        this.conds.push(cond);
    };

    this.show_plan_rank = 0;
    this.show_excluded_plans = 0;
    this.warn_before_clear_all = 1;

    this.raw_json_results = '';
    this.recommended_plans = [];
    this.data = '';

    this.add_recommended_plan = function (index, string) {
        if (this.recommended_plans[index] === undefined) {
            this.recommended_plans[index] = [];
        }
        this.recommended_plans[index].push(string);
    }
}