// src/HealthProfile.js

import React, { useEffect, useState, useRef } from 'react'
import { View, StyleSheet, Pressable, Button, useWindowDimensions, Dimensions } from 'react-native'
import Pane from './Pane';
import Row from './Row';
import * as Constants from '../theme'
import TextInputField, { SelectField, RadioField, SwitchButton, BooleanSwitch } from './Input';
import { AntDesign, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import * as Data from "./libs/data"

import { Select, CheckIcon, ChevronDownIcon, Radio, Icon } from "native-base";
import AddItemWizard from './AddItemWizard';
import MedicationDisplay, { ConditionDisplay } from './UnderwritingDisplay';
import Text from './Text';
import { v4 as uuidv4 } from 'uuid';

function HealthProfile(props) {
    const [openItem, setOpenItem] = useState(-1); // this represents the id of the item, -1 will represent opening a blank item

    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    const styles = new StyleSheet.create({
        addButton: {
            fontSize: Constants.fontSizes.input,
            fontWeight: '700',
            color: props.openItemWizard ? Constants.colors.disabledButton : Constants.colors.primaryButton,
            marginRight: 40,
            marginLeft: 8,
        },
        secondaryWrapStyle: {
            cursor: props.openItemWizard ? 'not-allowed' : 'pointer',
        },
        healthProfileHeader: {
            marginTop: 76,
            paddingBottom: 32,
            borderBottomWidth: 1,
            marginLeft: -1 * Constants.leftOffset,
            paddingLeft: Constants.leftOffset,
            fontWeight: '700',
            color: Constants.colors.textColor,
            borderColor: Constants.colors.gray,
            opacity: props.openItemWizard ? .3 : 1,
        },
        headerIcon: {
            color: Constants.colors.textColor,
            marginRight: 16.75
        },
        headerSortIcon: {
            float: 'right',
            marginRight: isMobile ? 30 : Constants.leftOffset * 2,
            color: Constants.colors.captionColor
        }

    });

    useEffect(() => {
        Data.scroll_into_view("wizard");
    }, [props.openItemWizard]);

    let medIndex = 0;

    const ShowWizardIfAppropriate = () => {
        if (!props.openItemWizard) return;

        if (openItem == -1) {
            if (!props.isRegistered) {
                props.setOpenItemWizard(false);
                return props.setShowSoftwareRegistrationModal(true);
            }
            return wizard;
        }
    }

    let [deleteMed, setDeleteMed] = useState(null);
    let [deleteCond, setDeleteCond] = useState(null);
    let [lastAction, setLastAction] = useState('');
    useEffect(() => {
        if (!deleteMed) return;

        let list = [...props.getMedications];
        let newList = list.filter(val => val.uuid !== deleteMed);
        props.setMedications(newList);
        setDeleteMed(null);
    }, [deleteMed]);

    useEffect(() => {
        if (!deleteCond) return;

        let list = [...props.getConditions];
        let newList = list.filter(val => val.uuid !== deleteCond);
        props.setConditions(newList);
        setDeleteCond(null);
    }, [deleteCond]);


    const ShowAllMedicationsOrWizard = () => {
        if (props.getMedications.length == 0) return;
        let list = [...props.getMedications];
        list = list.sort((a, b) => a.index - b.index);
        if (props.medicationSortNewestAtTop == '1') {
            list = [...list].reverse();
        }

        return <>
            <View>
                <Text testID="med-section-label" style={[styles.healthProfileHeader, props.getConditions.length > 0 ? { marginTop: Constants.leftOffset * 3.25 } : null,
                props.openItemWizard == false && props.getConditions.length == 0 ? { marginTop: 20 } : null
                ]}>
                    <Icon as={FontAwesome5} name="pills" style={styles.headerIcon}></Icon>Medication{props.getMedications.length > 1 ? 's' : ''}
                    {props.getMedications.length > 1 ? <Pressable
                        style={styles.headerSortIcon}
                        onPress={() => { toggleSort('medications') }}
                        accessibilityRole="button">

                        {props.medicationSortNewestAtTop == '1' ?
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"
                                title={`Change medication condition sorting to oldest first`}
                                role="img"
                                aria-label={`Change medication sorting to oldest first`}>
                                <path d="M4.70844 16.8613C5.02987 16.8613 5.33344 16.7256 5.5513 16.4899L8.69416 13.0613C9.11915 12.597 9.09058 11.872 8.62273 11.447C8.15487 11.022 7.43344 11.0506 7.00844 11.5185L5.8513 12.7792V2.00419C5.8513 1.37204 5.34058 0.861328 4.70844 0.861328C4.0763 0.861328 3.56558 1.37204 3.56558 2.00419V12.7792L2.40844 11.5185C1.98344 11.0542 1.25844 11.022 0.794155 11.447C0.329869 11.872 0.297726 12.597 0.722726 13.0613L3.86558 16.4899C4.08344 16.7256 4.38701 16.8613 4.70844 16.8613ZM11.5656 11.147C11.5656 11.7792 12.0763 12.2899 12.7084 12.2899H13.2799V14.5756H12.7084C12.0763 14.5756 11.5656 15.0863 11.5656 15.7185C11.5656 16.3506 12.0763 16.8613 12.7084 16.8613H14.4227H16.137C16.7692 16.8613 17.2799 16.3506 17.2799 15.7185C17.2799 15.0863 16.7692 14.5756 16.137 14.5756H15.5656V11.147C15.5656 10.5149 15.0549 10.0042 14.4227 10.0042H12.7084C12.0763 10.0042 11.5656 10.5149 11.5656 11.147ZM14.912 5.03633C14.762 5.10776 14.5977 5.14704 14.4227 5.14704C13.7906 5.14704 13.2799 4.63633 13.2799 4.00419C13.2799 3.37204 13.7906 2.86133 14.4227 2.86133C15.0549 2.86133 15.5656 3.37204 15.5656 4.00419C15.5656 4.46133 15.2977 4.85419 14.912 5.03633ZM13.4584 6.99704L13.2156 7.32561C12.8406 7.83276 12.9477 8.54704 13.4549 8.92561C13.962 9.30418 14.6763 9.19347 15.0549 8.68633L16.7977 6.33633C17.2977 5.66133 17.5656 4.84704 17.5656 4.00776C17.5656 2.27204 16.1584 0.864899 14.4227 0.864899C12.687 0.864899 11.2799 2.27204 11.2799 4.00776C11.2799 5.40776 12.1942 6.59347 13.462 7.00061L13.4584 6.99704Z" fill="#999999" />
                            </svg>

                            :
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"
                                title={`Change medication sorting to newest first`}
                                role="img"
                                aria-label={`Change medication sorting to newest first`}>
                                <path d="M5.13032 0.361328C5.45174 0.361328 5.75532 0.497042 5.97317 0.732757L9.11603 4.16133C9.54103 4.62561 9.51246 5.35061 9.0446 5.77561C8.57674 6.20061 7.85532 6.17204 7.43032 5.70419L6.27317 4.44347V15.2185C6.27317 15.8506 5.76246 16.3613 5.13032 16.3613C4.49817 16.3613 3.98746 15.8506 3.98746 15.2185V4.44347L2.83032 5.70419C2.40532 6.16847 1.68032 6.20061 1.21603 5.77561C0.751744 5.35061 0.719601 4.62561 1.1446 4.16133L4.28746 0.732757C4.50532 0.497042 4.80889 0.361328 5.13032 0.361328ZM11.9875 1.50419C11.9875 0.872042 12.4982 0.361328 13.1303 0.361328H14.8446C15.4767 0.361328 15.9875 0.872042 15.9875 1.50419V4.93276H16.5589C17.191 4.93276 17.7017 5.44347 17.7017 6.07561C17.7017 6.70776 17.191 7.21847 16.5589 7.21847H14.8446H13.1303C12.4982 7.21847 11.9875 6.70776 11.9875 6.07561C11.9875 5.44347 12.4982 4.93276 13.1303 4.93276H13.7017V2.64704H13.1303C12.4982 2.64704 11.9875 2.13633 11.9875 1.50419ZM15.3339 12.2506C15.7196 12.0685 15.9875 11.672 15.9875 11.2185C15.9875 10.5863 15.4767 10.0756 14.8446 10.0756C14.2125 10.0756 13.7017 10.5863 13.7017 11.2185C13.7017 11.8506 14.2125 12.3613 14.8446 12.3613C15.0196 12.3613 15.1875 12.322 15.3339 12.2506ZM13.8803 14.2113C12.616 13.8042 11.7017 12.6185 11.7017 11.2185C11.7017 9.48276 13.1089 8.07561 14.8446 8.07561C16.5803 8.07561 17.9875 9.48276 17.9875 11.2185C17.9875 12.0578 17.7196 12.872 17.2196 13.547L15.4767 15.897C15.1017 16.4042 14.3839 16.5113 13.8767 16.1363C13.3696 15.7613 13.2625 15.0435 13.6375 14.5363L13.8803 14.2078V14.2113Z" fill="#999999" />
                            </svg>
                        }
                    </Pressable> : null}
                </Text>

            </View>
            {
                list.map(function (med) {
                    if (!med.uuid) med.uuid = uuidv4();
                    if (openItem === med.uuid && props.openItemWizard) return wizard;

                    // Find index of this med in original array to maintain order
                    let displayIndex = list.findIndex(m => m.uuid === med.uuid);
                    if (props.medicationSortNewestAtTop == '1') {
                        displayIndex = Math.abs(list.length - displayIndex);
                    } else {
                        displayIndex = displayIndex + 1;
                    }

                    return (<MedicationDisplay
                        key={`${med.uuid}-${props.medicationSortNewestAtTop}`}
                        isRegistered={props.isRegistered}
                        readonly={props.isReadonly.current}
                        medicationSortNewestAtTop={props.medicationSortNewestAtTop}
                        setDeleteItem={setDeleteMed}
                        id={displayIndex}
                        object={med}
                        setOpenItem={setOpenItem}
                        openItemWizard={props.openItemWizard}
                        setOpenItemWizard={props.setOpenItemWizard}
                        uuid={med.uuid}
                        name={med.name()}
                        use={med.use()}
                        lastFill={med.last_fill()}
                        firstFill={med.first_fill()}
                        dosage={med.dosage()} />);
                })
            }
        </>;
    }

    const ShowAllConditionsOrWizard = () => {
        if (props.getConditions.length == 0) return;
        let list = [...props.getConditions];
        if (props.conditionSortNewestAtTop == '1') {
            list = [...list].reverse();
        }

        let localIdx = -1; // incrementing early
        return <>
            <View>
                <Text testID="cond-section-label" style={[styles.healthProfileHeader, { marginTop: 76 - 55 }]}><Icon as={FontAwesome} name="user" style={styles.headerIcon}></Icon>Condition{props.getConditions.length > 1 ? 's' : ''}
                    {props.getConditions.length > 1 ? <Pressable style={styles.headerSortIcon} onPress={() => { toggleSort('conditions') }}
                        accessibilityRole="button">

                        {props.conditionSortNewestAtTop == '1' ?
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"
                                title={`Change condition sorting to oldest first`}
                                role="img"
                                aria-label={`Change condition sorting to oldest first`}>
                                <path d="M4.70844 16.8613C5.02987 16.8613 5.33344 16.7256 5.5513 16.4899L8.69416 13.0613C9.11915 12.597 9.09058 11.872 8.62273 11.447C8.15487 11.022 7.43344 11.0506 7.00844 11.5185L5.8513 12.7792V2.00419C5.8513 1.37204 5.34058 0.861328 4.70844 0.861328C4.0763 0.861328 3.56558 1.37204 3.56558 2.00419V12.7792L2.40844 11.5185C1.98344 11.0542 1.25844 11.022 0.794155 11.447C0.329869 11.872 0.297726 12.597 0.722726 13.0613L3.86558 16.4899C4.08344 16.7256 4.38701 16.8613 4.70844 16.8613ZM11.5656 11.147C11.5656 11.7792 12.0763 12.2899 12.7084 12.2899H13.2799V14.5756H12.7084C12.0763 14.5756 11.5656 15.0863 11.5656 15.7185C11.5656 16.3506 12.0763 16.8613 12.7084 16.8613H14.4227H16.137C16.7692 16.8613 17.2799 16.3506 17.2799 15.7185C17.2799 15.0863 16.7692 14.5756 16.137 14.5756H15.5656V11.147C15.5656 10.5149 15.0549 10.0042 14.4227 10.0042H12.7084C12.0763 10.0042 11.5656 10.5149 11.5656 11.147ZM14.912 5.03633C14.762 5.10776 14.5977 5.14704 14.4227 5.14704C13.7906 5.14704 13.2799 4.63633 13.2799 4.00419C13.2799 3.37204 13.7906 2.86133 14.4227 2.86133C15.0549 2.86133 15.5656 3.37204 15.5656 4.00419C15.5656 4.46133 15.2977 4.85419 14.912 5.03633ZM13.4584 6.99704L13.2156 7.32561C12.8406 7.83276 12.9477 8.54704 13.4549 8.92561C13.962 9.30418 14.6763 9.19347 15.0549 8.68633L16.7977 6.33633C17.2977 5.66133 17.5656 4.84704 17.5656 4.00776C17.5656 2.27204 16.1584 0.864899 14.4227 0.864899C12.687 0.864899 11.2799 2.27204 11.2799 4.00776C11.2799 5.40776 12.1942 6.59347 13.462 7.00061L13.4584 6.99704Z" fill="#999999" />
                            </svg>

                            :
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"
                                title={`Change condition sorting to newest first`}
                                role="img"
                                aria-label={`Change condition sorting to newest first`}>
                                <path d="M5.13032 0.361328C5.45174 0.361328 5.75532 0.497042 5.97317 0.732757L9.11603 4.16133C9.54103 4.62561 9.51246 5.35061 9.0446 5.77561C8.57674 6.20061 7.85532 6.17204 7.43032 5.70419L6.27317 4.44347V15.2185C6.27317 15.8506 5.76246 16.3613 5.13032 16.3613C4.49817 16.3613 3.98746 15.8506 3.98746 15.2185V4.44347L2.83032 5.70419C2.40532 6.16847 1.68032 6.20061 1.21603 5.77561C0.751744 5.35061 0.719601 4.62561 1.1446 4.16133L4.28746 0.732757C4.50532 0.497042 4.80889 0.361328 5.13032 0.361328ZM11.9875 1.50419C11.9875 0.872042 12.4982 0.361328 13.1303 0.361328H14.8446C15.4767 0.361328 15.9875 0.872042 15.9875 1.50419V4.93276H16.5589C17.191 4.93276 17.7017 5.44347 17.7017 6.07561C17.7017 6.70776 17.191 7.21847 16.5589 7.21847H14.8446H13.1303C12.4982 7.21847 11.9875 6.70776 11.9875 6.07561C11.9875 5.44347 12.4982 4.93276 13.1303 4.93276H13.7017V2.64704H13.1303C12.4982 2.64704 11.9875 2.13633 11.9875 1.50419ZM15.3339 12.2506C15.7196 12.0685 15.9875 11.672 15.9875 11.2185C15.9875 10.5863 15.4767 10.0756 14.8446 10.0756C14.2125 10.0756 13.7017 10.5863 13.7017 11.2185C13.7017 11.8506 14.2125 12.3613 14.8446 12.3613C15.0196 12.3613 15.1875 12.322 15.3339 12.2506ZM13.8803 14.2113C12.616 13.8042 11.7017 12.6185 11.7017 11.2185C11.7017 9.48276 13.1089 8.07561 14.8446 8.07561C16.5803 8.07561 17.9875 9.48276 17.9875 11.2185C17.9875 12.0578 17.7196 12.872 17.2196 13.547L15.4767 15.897C15.1017 16.4042 14.3839 16.5113 13.8767 16.1363C13.3696 15.7613 13.2625 15.0435 13.6375 14.5363L13.8803 14.2078V14.2113Z" fill="#999999" />
                            </svg>
                        }

                    </Pressable> : null}
                </Text>

            </View>

            {
                list.map(function (cond) {
                    if (!cond.uuid) cond.uuid = uuidv4();
                    if (openItem === cond.uuid && props.openItemWizard) return wizard;

                    // Find index of this med in original array to maintain order
                    let displayIndex = list.findIndex(c => c.uuid === cond.uuid);
                    if (props.conditionSortNewestAtTop == '1') {
                        displayIndex = Math.abs(list.length - displayIndex);
                    } else {
                        displayIndex = displayIndex + 1;
                    }

                    let medStr = '';
                    if (props.getMedications && props.getMedications.filter(function (x) { return x.use() == cond.name() }).length > 0)
                        medStr = props.getMedications.filter(function (x) { return x.use() == cond.name() }).map(function (x) { return x.name() }).join(', ');

                    return (<ConditionDisplay
                        uuid={cond.uuid}
                        key={`${cond.name()}-${displayIndex}-${props.conditionSortNewestAtTop}`}
                        isRegistered={props.isRegistered} readonly={props.isReadonly.current} conditionSortNewestAtTop={props.conditionSortNewestAtTop} setDeleteItem={setDeleteCond} object={cond} id={displayIndex} meds={medStr} index={displayIndex} setOpenItem={setOpenItem} openItemWizard={props.openItemWizard} setOpenItemWizard={props.setOpenItemWizard}
                        name={cond.name()} lastTreatment={cond.last_treatment()} wasDiagnosed={cond.was_diagnosed()} />);
                })
            }
        </>;

        return conds;
    }

    const toggleSort = (which) => {
        if (which == 'conditions') {
            if (props.conditionSortNewestAtTop == '1') {
                props.setConditionSortNewestAtTop('0');
                Data.savePreference('conditionSortNewestAtTop', '0');
            } else {
                props.setConditionSortNewestAtTop('1');
                Data.savePreference('conditionSortNewestAtTop', '1');
            }
        }
        if (which == 'medications') {
            if (props.medicationSortNewestAtTop == '1') {
                props.setMedicationSortNewestAtTop('0');
                Data.savePreference('medicationSortNewestAtTop', '0');
            } else {
                props.setMedicationSortNewestAtTop('1');
                Data.savePreference('medicationSortNewestAtTop', '1');
            }
        }
    }

    let wizard = (
        <AddItemWizard {...props} openItem={openItem} setOpenItem={setOpenItem} lastAction={lastAction} setLastAction={setLastAction} itemIndex={props.itemIndex} />);

    return (
        <View style={[{ marginTop: 55, marginRight: -1 * Constants.leftOffset, paddingRight: Constants.leftOffset, zIndex: -5 }]} >
            <Pane
                id="health-profile"
                onLayout={() => { }} headingStyle={{ marginTop: 0, borderWidth: 0, }}
                heading='Health & Underwriting Profile' secondaryPress={() => {
                    if (!props.isRegistered) return props.setShowSoftwareRegistrationModal(true);
                    if (props.openItemWizard) return;
                    setOpenItem(-1);
                    props.setFocusOn('search');
                    props.setOpenItemWizard(true);
                }} secondaryWrapStyle={styles.secondaryWrapStyle} secondaryIcon={<Icon as={AntDesign} name="pluscircle" color={props.openItemWizard ? Constants.colors.disabledButton : Constants.colors.primaryButton} style={[props.readonly ? { display: 'none' } : null, { height: 16, width: 16, pointer: props.openItemWizard ? 'not-allowed' : 'cursor' }]} />} secondary='Add to Profile' secondaryStyle={[props.readonly ? { display: 'none' } : null, styles.addButton]} >
                <ShowWizardIfAppropriate />
                <ShowAllConditionsOrWizard conditionSortNewestAtTop={props.conditionSortNewestAtTop} />
                <ShowAllMedicationsOrWizard medicationSortNewestAtTop={props.medicationSortNewestAtTop} />

            </Pane>
        </View>);
}

export default HealthProfile


const showNicotineOptionsLinkTopSpacing = 14;


/* need to add actual custom css:
TextInputField[type=radio]
    background-color: // blue
*/
